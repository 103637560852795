import React, { useEffect, useState } from 'react';
import axios from 'axios';
import CreateQuotation from '../components/CreateQuatation'; // Import the CreateQuotation component
import './css/AllClient.css';
import CreatePI from './CreatePI';
import AddClient from './AddClient';

const AllClients = ({ toggleComponent, selectedCompany2 }) => {
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const [selectedQuotation, setSelectedQuotation] = useState(null); // State for selected quotation
  const [searchQuery, setSearchQuery] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectedQuatationData, setSelectedQuatationData] = useState(null);
  const [selectedPIData, setSelectedPIData] = useState(null);
  const [selectedType, setSelectedType] = useState();
  const [selectedPi, setSelectedPi] = useState(null);
  const [quotationSearchTerm, setQuotationSearchTerm] = useState("");
  const [searchPI, setSearchPI] = useState('');
  const [isEdit,setIsEdit] = useState(false);

  const filteredPI = selectedClient && selectedClient.perfomaInvoice?.filter((pi) =>
    pi.id.toLowerCase().includes(searchPI.toLowerCase()) ||
    new Date(pi.date).toLocaleDateString().includes(searchPI) ||
    pi.companyId.toLowerCase().includes(searchPI.toLowerCase())
  );



  // Filtered Quotations
  const filteredQuotations = selectedClient && selectedClient.quotation?.filter((quotation) =>
    quotation.id.toLowerCase().includes(quotationSearchTerm.toLowerCase()) ||
    quotation.companyId.toLowerCase().includes(quotationSearchTerm.toLowerCase())
  );

  // Fetch clients data
  useEffect(() => {
    const fetchClients = async () => {
      const token = localStorage.getItem('token');
      try {
        const response = await axios.get(`https://emkayindia.in/api/v1/client/all?companyID=${selectedCompany2.id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        console.log(response);
        setClients(response.data);
      } catch (error) {
        console.error('Error fetching clients', error);
      }
    };

    fetchClients();
  }, []);

  const handleEditClient = (client)=>{
    setSelectedClient(client);
    setIsEdit(true);
  }


  // Handle client selection
  const handleClientClick = (client, type) => {
    console.log(type);
    setSelectedClient(client);
    setIsEditing(false);
    setSelectedType(type);
  };

  // Handle search
  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  // Handle quotation click
  const handleQuotationClick = (quotation) => {
    setSelectedQuotation(quotation);
    fetchCompanyFromCompanyId(quotation);
    fetchQuatationData(quotation);
  };
  const handlePIClick = (pi) => {
    console.log(pi)
    setSelectedPi(pi);
    fetchCompanyFromCompanyId(pi);
    fetchPIData(pi);
  };

  const fetchQuatationData = async (quotation) => {
    const token = localStorage.getItem('token');
    try {
      const response = await axios.get(`https://emkayindia.in/api/v1/quotation/get/${quotation.id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setSelectedQuatationData(response.data);
    } catch (error) {
      console.log('Error While fetching quotation data');
    }
  };
  const fetchPIData = async (quotation) => {
    const token = localStorage.getItem('token');
    try {
      const response = await axios.get(`https://emkayindia.in/api/v1/perfoma/get/${quotation.id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setSelectedPIData(response.data);
    } catch (error) {
      console.log('Error While fetching quotation data');
    }
  };


  const fetchCompanyFromCompanyId = async (quotation) => {
    const token = localStorage.getItem('token');
    try {
      const response = await axios.get(`https://emkayindia.in/api/v1/company/get/${quotation.companyId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setSelectedCompany(response.data);
    } catch (error) {
      console.log('Error While fetching company');
    }
  };

  // Filter clients based on search query
  const filteredClients = clients.filter(
    (client) =>
      client.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      client.nickname.toLowerCase().includes(searchQuery.toLowerCase()) ||
      client.id.toString().includes(searchQuery)
  );

  return (
    <div className=" font-sans w-[105%] overflow-y-auto overflow-x-hidden h-[100%] text-[#848484]">
      {/* All Clients Section */}
      {!selectedClient && !selectedQuotation && !selectedPi && !isEdit && (
        <div className="mt-4 w-[100%] p-4 mx-auto">
          <h2 className="text-3xl font-bold mb-4">All Clients</h2>
          <div className="mb-6 mt-6 flex justify-between w-[50%]">
            <label className="text-lg">Search Client Name:</label>
            <input
              type="text"
              value={searchQuery}
              onChange={handleSearch}
              placeholder="Search clients..."
              className="w-[75%] px-4 py-2 border rounded-lg shadow-md focus:outline-none focus:ring focus:border-blue-300"
            />
          </div>
          {/* Render Clients Table */}
          <div className="w-[100%]">
            <table className="w-[90%] table-auto border-collapse bg-white shadow-md rounded-lg">
              <thead className="bg-gray-200">
                <tr>
                  <th className="px-6 py-3 text-left">ID</th>
                  <th className="px-6 py-3 text-left">Name</th>
                  <th className="px-6 py-3 text-left">Nickname</th>
                  <th className="px-6 py-3 text-left w-[17%]"> Action </th>
                  <th  className="px-6 py-3 text-left w-[13%]">Edit/View</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {filteredClients.length > 0 ? (
                  filteredClients.map((client) => (
                    <tr
                      key={client.id}
                      className=""

                    >
                      <td className="px-6 py-2 border">{client.id}</td>
                      <td className="px-6 py-2 border">{client.name}</td>
                      <td className="px-6 py-2 border">{client.nickname}</td>

                      <td className='flex flex-col items-center border'>
                        <td className="px-6  text-blue-400 hover:underline hover:bg-gray-100 cursor-pointer transition duration-200" onClick={() => handleClientClick(client, "quotation")}>All Quatations</td>
                        <td className="px-6   text-blue-400 hover:underline hover:bg-gray-100 cursor-pointer transition duration-200" onClick={() => handleClientClick(client, "PI")}>All PI</td>
                      </td>

                      <td className='border'>
                        <td className="px-6   text-blue-400 hover:underline hover: cursor-pointer transition duration-200" onClick={() => handleEditClient(client)}>Edit Client</td>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="3" className="text-center py-4 text-gray-500">
                      No clients found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      )}

      {/* Client Details Section */}
      {selectedClient && !selectedQuotation && !selectedPi && !isEdit && (
        <div className="w-[96%] mt-4 p-4">
          <div className='flex justify-end mr-6'>

            <button
              className=" bg-[#C6C5B9] text-[#848484] font-semibold w-[17%] py-3 px-4 rounded-lg "
              onClick={() => {
                setSelectedClient(null)
                setSelectedType("");
              }}
            >
              Back to All Clients
            </button>
          </div>


          {/* <div className="client-details p-6 ">
            <p className="text-gray-700">
              <strong>Name:</strong> {selectedClient.name}
            </p>
            <p className="text-gray-700">
              <strong>Nickname:</strong> {selectedClient.nickname}
            </p>
            <p className="text-gray-700">
              <strong>Email:</strong> {selectedClient.email}
            </p>
            <p className="text-gray-700">
              <strong>GST No.:</strong> {selectedClient.gstNo}
            </p>
            <p className="text-gray-700">
              <strong>Phone:</strong> {selectedClient.phone}
            </p>
            <p className="text-gray-700">
              <strong>Address:</strong> {selectedClient.address.split('/0')[0]}<p>{selectedClient.address.split('/0')[1]}</p>
            </p>
            <p className="text-gray-700">
              <strong>Contact Person:</strong> {selectedClient.contactPerson}
            </p>
          </div> */}
          <div className="client-details p-2 w-[70%] ">
            <h2 className="text-2xl font-semibold mb-4">Client Details</h2>
            <div className="grid grid-cols-2 gap-4">
              <div className="flex flex-col text-lg">
                <p className="text-[#848484]">
                  <span className="font-semibold inline-block w-[20%]">Name</span> :  {selectedClient.name}
                </p>
                <p className="text-[#848484]">
                  <span className="font-semibold inline-block w-[20%]">Nickname</span> :  {selectedClient.nickname}
                </p>
                <p className="text-[#848484]">
                  <span className="font-semibold inline-block w-[20%]">Email</span> :  {selectedClient.email}
                </p>
                <p className="text-[#848484]">
                  <span className="font-semibold inline-block w-[20%]">GST No.</span> :  {selectedClient.gstNo}
                </p>
              </div>
              <div className="flex flex-col">
                <p className="text-[#848484]">
                  <span className="font-semibold inline-block w-[30%]">Phone</span> :  {selectedClient.phone}
                </p>
                <p className="text-[#848484]">
                  <p className="text-[#848484]">
                    <span className="font-semibold inline-block w-[30%]">Contact Person</span> :  {selectedClient.contactPerson}
                  </p>
                  <span className="font-semibold w-[30%] inline-block">Address</span> :
                  <span>   {selectedClient.address.split('/0')[0]}
                    <p className='pl-[33%]'>{selectedClient.address.split('/0')[1]}</p>
                  </span>
                </p>

              </div>
            </div>
          </div>
          {/* {selectedType==="quotation" && <div>
            <h2 className="text-2xl font-semibold p-2 mb-6">All Quotations</h2>
            {selectedClient.quotation?.length > 0 ? (
              <div className="w-[95%]">
                <table className="w-full table-auto border-collapse bg-white shadow-md rounded-lg">
                  <thead className="bg-gray-200">
                    <tr>
                      <th className="px-6 py-3 text-left">Quotation ID</th>
                      <th className="px-6 py-3 text-left">Date</th>
                      <th className="px-6 py-3 text-left">Company ID</th>
                      <th className="px-6 py-3 text-left">Actions</th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {selectedClient.quotation
                      .sort((a, b) => new Date(b.date) - new Date(a.date)).map((quotation) => (
                        <tr key={quotation.id}>
                          <td className="px-6 py-4">{quotation.id}</td>
                          <td className="px-6 py-4">
                            {new Date(quotation.date).toLocaleDateString()}
                          </td>
                          <td className="px-6 py-4">{quotation.companyId}</td>
                          <td className="px-6 py-4">
                            <button
                              className="text-blue-500 hover:underline"
                              onClick={() => handleQuotationClick(quotation)}
                            >
                              Edit Quotation
                            </button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <p className="text-gray-500">
                No quotations available for this client.
              </p>
            )}
          </div>} */}
          {selectedType === "quotation" && (
            <div>
              <h2 className="text-2xl font-semibold p-2 mb-6">All Quotations</h2>

              {/* Search Input */}
              <div className="mb-4">
                <input
                  type="text"
                  placeholder="Search by Quotation ID or Company ID"
                  className="px-4 py-2 border rounded-lg w-[95%]"
                  value={quotationSearchTerm}
                  onChange={(e) => setQuotationSearchTerm(e.target.value)}
                />
              </div>

              {/* Filtered Quotations */}
              {filteredQuotations?.length > 0 ? (
                <div className="w-[95%]">
                  <table className="w-full table-auto border-collapse bg-white shadow-md rounded-lg">
                    <thead className="bg-gray-200">
                      <tr>
                        <th className="px-6 py-3 text-left">Quotation ID</th>
                        <th className="px-6 py-3 text-left">Date</th>
                        <th className="px-6 py-3 text-left">Company ID</th>
                        <th className="px-6 py-3 text-left">Actions</th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {filteredQuotations
                        .sort((a, b) => new Date(b.date) - new Date(a.date))
                        .map((quotation) => (
                          <tr key={quotation.id}>
                            <td className="px-6 py-4">{quotation.id}</td>
                            <td className="px-6 py-4">
                              {new Date(quotation.date).toLocaleDateString()}
                            </td>
                            <td className="px-6 py-4">{quotation.companyId}</td>
                            <td className="px-6 py-4">
                              <button
                                className="text-blue-500 hover:underline"
                                onClick={() => handleQuotationClick(quotation)}
                              >
                                Edit Quotation
                              </button>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                <p className="text-gray-500">
                  No quotations found matching your search.
                </p>
              )}
            </div>
          )}

          {/* {selectedType === "PI" && <div>
            <h2 className="text-2xl font-semibold p-2 mb-6">All PI</h2>
            {selectedClient.quotation?.length > 0 ? (
              <div className="w-[95%]">
                <table className="w-full table-auto border-collapse bg-white shadow-md rounded-lg">
                  <thead className="bg-gray-200">
                    <tr>
                      <th className="px-6 py-3 text-left">PI ID</th>
                      <th className="px-6 py-3 text-left">Date</th>
                      <th className="px-6 py-3 text-left">Company ID</th>
                      <th className="px-6 py-3 text-left">Actions</th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {selectedClient.perfomaInvoice
                      .sort((a, b) => new Date(b.date) - new Date(a.date)).map((pi) => (
                        <tr key={pi.id}>
                          <td className="px-6 py-4">{pi.id}</td>
                          <td className="px-6 py-4">
                            {new Date(pi.date).toLocaleDateString()}
                          </td>
                          <td className="px-6 py-4">{pi.companyId}</td>
                          <td className="px-6 py-4">
                            <button
                              className="text-blue-500 hover:underline"
                              onClick={() => handlePIClick(pi)}
                            >
                              Edit PI
                            </button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <p className="text-gray-500">
                No PI available for this client.
              </p>
            )}
          </div>} */}
          {selectedType === "PI" && (
            <div>
              <h2 className="text-2xl font-semibold p-2 mb-6">All PI</h2>

              {/* Search Input */}
              <div className="mb-4">
                <input
                  type="text"
                  placeholder="Search by PI ID, Date, or Company ID"
                  value={searchPI} // Controlled search input state
                  onChange={(e) => setSearchPI(e.target.value)}
                  className="w-[95%] px-4 py-2 border border-gray-300 rounded-md shadow-sm"
                />
              </div>

              {/* Filtered PI List */}
              {filteredPI.length > 0 ? (
                <div className="w-[95%]">
                  <table className="w-full table-auto border-collapse bg-white shadow-md rounded-lg">
                    <thead className="bg-gray-200">
                      <tr>
                        <th className="px-6 py-3 text-left">PI ID</th>
                        <th className="px-6 py-3 text-left">Date</th>
                        <th className="px-6 py-3 text-left">Company ID</th>
                        <th className="px-6 py-3 text-left">Actions</th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {filteredPI
                        .sort((a, b) => new Date(b.date) - new Date(a.date))
                        .map((pi) => (
                          <tr key={pi.id}>
                            <td className="px-6 py-4">{pi.id}</td>
                            <td className="px-6 py-4">
                              {new Date(pi.date).toLocaleDateString()}
                            </td>
                            <td className="px-6 py-4">{pi.companyId}</td>
                            <td className="px-6 py-4">
                              <button
                                className="text-blue-500 hover:underline"
                                onClick={() => handlePIClick(pi)}
                              >
                                Edit PI
                              </button>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                <p className="text-gray-500">No PI available for this client.</p>
              )}
            </div>
          )}

        </div>
      )}

      {/* Quotation Edit Section */}
      {selectedQuotation && (
        <CreateQuotation
          selectedQuotation={selectedQuatationData}
          selectedCompany={selectedCompany}
          onBack={() => setSelectedQuotation(null)}
          toggleComponent={toggleComponent}
        />
      )}
      {selectedPi && (
        <CreatePI
          selectedQuotation={selectedPIData}
          selectedCompany={selectedCompany}
          onBack={() => setSelectedPIData(null)}
          toggleComponent={toggleComponent}
        />
      )}
      {
        selectedClient && isEdit && (
          <AddClient
          isEdit={isEdit}
          selectedClient = {selectedClient}
          setIsEdit={setIsEdit}
          selectedCompany={selectedCompany}
          toggleComponent={toggleComponent}
          setSelectedClient={setSelectedClient}
        />
        )
      }
    </div>
  );
};

export default AllClients;
