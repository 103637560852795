import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";

const EditCompany = ({ companyId, onSave, onCancel }) => {
  const [company, setCompany] = useState({
    companyName: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    pincode: "",
    email: "",
    phone: "",
    gstNo: "",
    logo: null,
    banner: null,
    termsAndConditions: [""],
    bankName: "",
    bankBranch: "",
    bankAccount: "",
    bankIFSC: "",
    banners: {
      banner1: null,
      banner2: null,
      banner3: null,
      banner4: null,
      banner5: null,
    },
  });

  const [initialLogo, setInitialLogo] = useState(null);
  const [initialBanner, setInitialBanner] = useState(null);
  const [defaultBanner, setDefaultBanner] = useState(null);
  

  useEffect(() => {
    const fetchCompanyDetails = async () => {
      const token = localStorage.getItem("token");
      try {
        const response = await axios.get(
          `https://emkayindia.in/api/v1/company/get/${companyId}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        const data = response.data;
        console.log(data);
        setCompany({
          ...company,
          companyName: data.companyName || "",
          addressLine1: data.address ? data.address.split("/0")[0] : "",
          addressLine2: data.address ? data.address.split("/0")[1] : "",
          city: data.city || "",
          state: data.state || "",
          pincode: data.pincode || "",
          email: data.email || "",
          phone: data.phone || "",
          gstNo: data.gstNo || "",
          termsAndConditions: data.terms.termsAndConditions || [""],
          bankName: data.bankName || "",
          bankBranch: data.bankBranch || "",
          bankAccount: data.bankAccount || "",
          bankIFSC: data.bankIFSC || "",
          logo: data.logo,
          banners: {
            banner1: data.banner1 || null,
            banner2: data.banner2 || null,
            banner3: data.banner3 || null,
            banner4: data.banner4 || null,
            banner5: data.banner5 || null,
          },
        });
        setDefaultBanner(data.defaultBanner);
        setInitialLogo(data.logo);
        setInitialBanner(data.banner);
      } catch (error) {
        console.error("Error fetching company details:", error);
      }
    };

    fetchCompanyDetails();
  }, [companyId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCompany({ ...company, [name]: value });
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setCompany({ ...company, [name]: files[0] });
  };

  const handleSetDefaultBanner = async (bannerUrl) => {
    // const token = localStorage.getItem("token");

    // try {
    //   await axios.patch(
    //     `https://emkayindia.in/api/v1/company/banner/set-default/${companyId}`,
    //     { defaultBanner: bannerUrl },
    //     {
    //       headers: {
    //         Authorization: `Bearer ${token}`,
    //       },
    //     }
    //   );
    //   toast.success("Default banner updated successfully!");
    //   setDefaultBanner(bannerUrl);
    // } catch (error) {
    //   console.error("Error setting default banner:", error);
    //   toast.error("Failed to set default banner!");
    // }
    company.banner=bannerUrl;
  };

  const handleBannerUpload = async (e, bannerKey) => {
    const token = localStorage.getItem("token");
    const newBanner = e.target.files[0];

    if (!newBanner) return;

    const formData = new FormData();
    formData.append("image", newBanner);

    try {
      const response = await axios.post(
        'https://emkayindia.in/api/v1/image/upload',
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response.data);
      toast.success(`${bannerKey} uploaded successfully!`);
      setCompany((prev) => ({
        ...prev,
        banners: { ...prev.banners, [bannerKey]: response.data.url },
      }));
    } catch (error) {
      console.error(`Error uploading ${bannerKey}:`, error);
      toast.error(`Failed to upload ${bannerKey}!`);
    }
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   const token = localStorage.getItem("token");
  //   const formData = new FormData();

  //   for (const key in company) {
  //     if (Array.isArray(company[key])) {
  //       company[key].forEach((value, index) => {
  //         formData.append(`${key}[${index}]`, value);
  //       });
  //     } else if (key === "logo" && company.logo === initialLogo) {
  //       continue; // Skip uploading logo if it hasn't changed
  //     } else if (key === "banner" && company.banner === initialBanner) {
  //       continue; // Skip uploading banner if it hasn't changed
  //     } else {
  //       formData.append(key, company[key]);
  //     }
  //   }

  //   try {
  //     await axios.patch(
  //       `https://emkayindia.in/api/v1/company/update/${companyId}`,
  //       formData,
  //       {
  //         headers: { Authorization: `Bearer ${token}`},
  //       }
  //     );
  //     console.log(formData);
  //     toast.success("Company updated successfully!");
  //     onSave();
  //   } catch (error) {
  //     console.error("Error updating company:", error);
  //     toast.error("Failed to update company!");
  //   }
  // };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem("token");
    const bannerData = Object.entries(company.banners).reduce((acc, [key, value]) => {
      if (value) acc[key] = value; // Include only non-null banners
      return acc;
    }, {});
    console.log(company.banners);

    // Separate JSON data and file uploads
    const jsonPayload = {
      ...company,
      address:company.addressLine1+' /0 '+company.addressLine2,
      terms: {
        termsAndConditions: company.termsAndConditions // No extra braces around company.termsAndConditions
      },
      banner1:company.banners.banner1,
      banner2:company.banners.banner2,
      banner3:company.banners.banner3,
      banner4:company.banners.banner4,
      banner5:company.banners.banner5,
      
    };
    
    console.log(jsonPayload);
    // jsonPayload.terms.termsAndConditions = company.termsAndConditions;
    // console.log(jsonPayload.terms);
    const formData = new FormData();

    // Handle files (logo and banner)
    if (company.logo !== initialLogo && company.logo) {
      formData.append("logo", company.logo);
      delete jsonPayload.logo; // Remove file from JSON payload
    }

    if (company.banner !== initialBanner && company.banner) {
      formData.append("banner", company.banner);
      delete jsonPayload.banner; // Remove file from JSON payload
    }

    try {
      // First, send the JSON payload
      const response = await axios.patch(
        `https://emkayindia.in/api/v1/company/update/${companyId}`,
        jsonPayload,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response);
      // Then, send the form data if there are files
      if (formData.has("logo") || formData.has("banner")) {
        await axios.patch(
          `https://emkayindia.in/api/v1/company/update/${companyId}`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      }
      // console.log(formData);
      toast.success("Company updated successfully!");
      onSave();
    } catch (error) {
      console.error("Error updating company:", error);
      toast.error("Failed to update company!");
    }
  };


  const addTerm = () => {
    setCompany({
      ...company,
      termsAndConditions: [...company.termsAndConditions, ""],
    });
  };

  const removeTerm = (index) => {
    const updatedTerms = company.termsAndConditions.filter((_, i) => i !== index);
    setCompany({ ...company, termsAndConditions: updatedTerms });
  };

  const updateTerm = (index, value) => {
    const updatedTerms = [...company.termsAndConditions];
    updatedTerms[index] = value;
    setCompany({ ...company, termsAndConditions: updatedTerms });
  };

  return (
    <div className="pl-4 pr-4 bg-[#f9f9f9] text-[#848484] font-sans">
      <h2 className="text-3xl font-bold mb-4  font-sans">Edit Company</h2>
      <form onSubmit={handleSubmit} className="grid gap-6 mb-24">
        {/* Company Details */}
        <div className="grid grid-cols-2 gap-6">
          <div>
            <label className="block text-lg">Company Name</label>
            <input
              type="text"
              name="companyName"
              value={company.companyName}
              onChange={handleInputChange}
              className="w-full border-2 border-gray-200 px-4 py-2"
            />
          </div>
          <div>
            <label className="block  text-lg">GST Number</label>
            <input
              type="text"
              name="gstNo"
              value={company.gstNo}
              onChange={handleInputChange}
              className="w-full border-2 border-gray-200 px-4 py-2"
            />
          </div>
        </div>

        {/* Address Details */}
        <div className="grid grid-cols-2 gap-6">
          <div>
            <label className="block  text-lg">Address Line 1</label>
            <input
              type="text"
              name="addressLine1"
              value={company.addressLine1}
              onChange={handleInputChange}
              className="w-full border-2 border-gray-200 px-4 py-2"
            />
          </div>
          <div>
            <label className="block text-lg">Address Line 2</label>
            <input
              type="text"
              name="addressLine2"
              value={company.addressLine2}
              onChange={handleInputChange}
              className="w-full border-2 border-gray-200 px-4 py-2"
            />
          </div>
          <div>
            <label className="block text-lg">City</label>
            <input
              type="text"
              name="city"
              value={company.city}
              onChange={handleInputChange}
              className="w-full border-2 border-gray-200 px-4 py-2"
            />
          </div>
          <div>
            <label className="block text-lg">State</label>
            <input
              type="text"
              name="state"
              value={company.state}
              onChange={handleInputChange}
              className="w-full border-2 border-gray-200 px-4 py-2"
            />
          </div>
          <div>
            <label className="block text-lg">Pincode</label>
            <input
              type="text"
              name="pincode"
              value={company.pincode}
              onChange={handleInputChange}
              className="w-full border-2 border-gray-200 px-4 py-2"
            />
          </div>
        </div>

        {/* Contact Details */}
        <div className="grid grid-cols-2 gap-6">
          <div>
            <label className="block text-lg">Email</label>
            <input
              type="email"
              name="email"
              value={company.email}
              onChange={handleInputChange}
              className="w-full border-2 border-gray-200 px-4 py-2"
            />
          </div>
          <div>
            <label className="block text-lg">Phone</label>
            <input
              type="text"
              name="phone"
              value={company.phone}
              onChange={handleInputChange}
              className="w-full border-2 border-gray-200 px-4 py-2"
            />
          </div>
        </div>

        {/* Bank Details */}
        <div className="grid grid-cols-2 gap-6">
          <div>
            <label className="block text-lg">Bank Name</label>
            <input
              type="text"
              name="bankName"
              value={company.bankName}
              onChange={handleInputChange}
              className="w-full border-2 border-gray-200 px-4 py-2"
            />
          </div>
          <div>
            <label className="block text-lg">Branch</label>
            <input
              type="text"
              name="bankBranch"
              value={company.bankBranch}
              onChange={handleInputChange}
              className="w-full border-2 border-gray-200 px-4 py-2"
            />
          </div>
          <div>
            <label className="block text-lg">Account Number</label>
            <input
              type="text"
              name="bankAccount"
              value={company.bankAccount}
              onChange={handleInputChange}
              className="w-full border-2 border-gray-200 px-4 py-2"
            />
          </div>
          <div>
            <label className="block  text-lg">IFSC Code</label>
            <input
              type="text"
              name="bankIFSC"
              value={company.bankIFSC}
              onChange={handleInputChange}
              className="w-full border-2 border-gray-200 px-4 py-2"
            />
          </div>
        </div>

        {/* Logo and Banner */}
        <div className=" gap-6">
          <div>
            <label className="block text-lg border-b-2">Logo</label>
            <input
              type="file"
              name="logo"
              onChange={handleFileChange}
              className="w-full border-gray-200 rounded-md px-3 py-2"
            />
            {initialLogo && (
              <img
                src={initialLogo}
                alt="Current Logo"
                className="mt-2 h-20 object-contain"
              />
            )}
          </div>
          {/* <div className="mt-4">
            <label className="block text-lg border-b-2">Banners</label>
            <input
              type="file"
              name="banner"
              onChange={handleFileChange}
              className="w-full border-gray-400 rounded-md px-3 py-2"
            />
            {initialBanner && (
              <img
                src={initialBanner}
                alt="Current Banner"
                className="mt-2 h-20 object-cover w-[100%]"
              />
            )}
          </div> */}
          <div className="mb-6">
        <h3 className="text-xl font-semibold mb-2">Banners</h3>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {Object.entries(company.banners).map(([key, banner], index) => (
            <div key={index} className="relative group">
              {banner ? (
                <>
                  <img
                    src={banner}
                    alt={`${key}`}
                    className="h-40 w-full object-cover border rounded"
                  />
                  {defaultBanner != banner && (
                    <button
                      onClick={() => handleSetDefaultBanner(banner)}
                      className="absolute top-2 right-2 bg-blue-500 text-white px-2 py-1 text-xs rounded"
                    >
                      Set Default
                    </button>
                  )}
                  {defaultBanner === banner && (
                    <span className="absolute top-2 right-2 bg-green-500 text-white px-2 py-1 text-xs rounded">
                      Default
                    </span>
                  )}
                </>
              ) : (
                <div className="border border-dashed rounded h-40 flex items-center justify-center">
                  <label className="block text-center">
                    <span className="text-sm text-gray-500">Upload {key}</span>
                    <input
                      type="file"
                      className="hidden"
                      onChange={(e) => handleBannerUpload(e, key)}
                    />
                  </label>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>

        </div>

        {/* Terms and Conditions */}
        <div>
          <label className="block text-xl mb-2">Terms and Conditions</label>
          {company.termsAndConditions.map((term, index) => (
            <div key={index} className="flex items-center gap-2 mb-2">
              <input
                type="text"
                value={term}
                onChange={(e) => updateTerm(index, e.target.value)}
                className="w-full border-2 border-gray-200 px-4 py-2"
              />
              <button
                type="button"
                onClick={() => removeTerm(index)}
                className="text-red-500"
              >
                Remove
              </button>
            </div>
          ))}
          <button
            type="button"
            onClick={addTerm}
            className="text-blue-500 mt-2"
          >
            Add Term
          </button>
        </div>

        {/* Submit Buttons */}
        <div className="flex justify-end gap-4">
          <button
            type="button"
            onClick={onCancel}
            className="border-2 px-4 py-2 rounded-md"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="bg-[#C6C5B9] w-[10%] text-[#848484] px-4 py-2 rounded-md"
          >
            Save
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditCompany;
