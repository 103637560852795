import React, { useState, useEffect, useRef } from "react";
import "./css/CreateQuatation.css";
import axios from "axios";
import { toast } from "react-toastify";
import A4Quotation from "./a4-quotation";
import A4sgst from "./a4-quotIgCgst";
import { blue } from "@mui/material/colors";
function useDebouncedEffect(callback, delay, dependencies) {
  useEffect(() => {
    const handler = setTimeout(() => callback(), delay);

    return () => {
      clearTimeout(handler); // Clear timeout on unmount or when dependencies change
    };
  }, [...dependencies]);
}

const CreatePI = ({
  toggleComponent,
  selectedQuotation,
  selectedCompany,
}) => {
  const initialProductRow = {
    serialNo: "",
    productId: "",
    name: "",
    hsn: "",
    brand: "",
    variant: "",
    quantity: "",
    price: "",
    discount: "",
    gst: "",
    total: "",
    searchTerm: "",
    suggestions: [],
  };
  const initialRows = Array.from({ length: 5 }, (_, index) => ({
    ...initialProductRow,
    serialNo: index + 1,
  }));
  const [billingProducts, setBillingProducts] = useState(initialRows); // Start with a single product row
  const [terms, setTerms] = useState([]); // Terms & Conditions state
  const [clientSearchTerm, setClientSearchTerm] = useState("");
  const [clientSuggestions, setClientSuggestions] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const [printData, setPrintData] = useState(null);
  const [billType, setBillType] = useState("PI"); // Invoice Type state
  const [brands, setBrands] = useState([]); // Product Brands state
  const [variants, setVariants] = useState([]); // Product Variants state
  const [productSearchTerm, setProductSearchTerm] = useState("");
  const [currIndex, setCurrIndex] = useState();
  const [showPrintPreview, setShowPrintPreview] = useState(false);
  const [quotationData, setQuotationData] = useState(null);
  const [selectrfProductName, setSelectedProductName] = useState();
  const [selectedBrand, setSelectedBrand] = useState();
  const [selectedVariant, setSelectedVariant] = useState();
  const [productSuggestionList, setProductSuggestionList] = useState([]);
  // const [currIndex,setCurrIndex] = useEffect();
  const [highlightedIndex, setHighlightedIndex] = useState(-1); // Track the highlighted suggestion index
  const suggestionsRef = useRef(null);
  const [filteredVariants, setFilteredVariants] = useState(variants);
  const [searchVariant, setSearchVariant] = useState("");
  const [packaging, setPackaging] = useState(0);
  const [allProducts, setAllProducts] = useState();
  const [allClients, setAllClients] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleVariantFilter = (index, searchTerm) => {
    setSearchVariant(searchTerm); // Save the typed initials
    setFilteredVariants(
      searchTerm
        ? variants.filter((variant) =>
          variant.toLowerCase().includes(searchTerm.toLowerCase())
        )
        : variants // If no input, show all variants
    );
  };




  useEffect(() => {
    if (selectedQuotation) {
      setSelectedClient(selectedQuotation.client || null);
      console.log(selectedQuotation.items);
      setBillingProducts(selectedQuotation.items || []);
      setTerms(selectedQuotation.terms || ["", "", ""]);
    }
  }, [selectedQuotation]);

  const handleKeyDown = (e) => {
    if (e.key === "ArrowDown") {
      setHighlightedIndex((prev) =>
        Math.min(prev + 1, productSuggestionList.length - 1)
      );
      e.preventDefault(); // Prevent default scrolling behavior
    } else if (e.key === "ArrowUp") {
      setHighlightedIndex((prev) => Math.max(prev - 1, 0));
      e.preventDefault(); // Prevent default scrolling behavior
    } else if (e.key === "Enter") {
      if (highlightedIndex >= 0) {
        handleSelectProduct(currIndex, productSuggestionList[highlightedIndex]);
        setHighlightedIndex(-1); // Reset highlighted index
      }
    } else if (e.key === "Escape") {
      setHighlightedIndex(-1); // Reset highlighted index on escape
    }
  };
  const handleKeyDownClientSearch = (e) => {
    if (e.key === "ArrowDown") {
      setHighlightedIndex((prev) =>
        Math.min(prev + 1, clientSuggestions.length - 1)
      );
      e.preventDefault();
    } else if (e.key === "ArrowUp") {
      setHighlightedIndex((prev) => Math.max(prev - 1, 0));
      e.preventDefault();
    } else if (e.key === "Enter") {
      if (highlightedIndex >= 0) {
        const client = clientSuggestions[highlightedIndex];
        setSelectedClient(client);
        setClientSearchTerm(""); // Clear search term after selection
        setHighlightedIndex(-1); // Reset highlighted index
      }
    } else if (e.key === "Escape") {
      setHighlightedIndex(-1); // Reset highlighted index on escape
    }
  };
  useEffect(() => {
    if (suggestionsRef.current) {
      const activeItem = suggestionsRef.current.children[highlightedIndex];
      if (activeItem) {
        activeItem.scrollIntoView({
          block: "nearest",
        });
      }
    }
  }, [highlightedIndex]);

  useEffect(() => {
    if (selectedCompany) setTerms(selectedCompany.terms.termsAndConditions);
  }, []);
  useEffect(() => {
    const fetchAllProducts = async () => {
      const token = localStorage.getItem("token");
      setIsLoading(true);

      try {
        const response = await axios.get(
          `https://emkayindia.in/api/v1/product/all?companyId=${selectedCompany.id}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        console.log(response.data);
        setAllProducts(response.data); // Store the complete product list
      } catch (error) {
        console.error("Error fetching all products:", error);
      } finally {
        setIsLoading(false);
      }
    };

    if (selectedCompany && selectedCompany.id) {
      fetchAllProducts();
    }
  }, [selectedCompany ? selectedCompany.id : ""]);

  useEffect(() => {
    fetchProductSuggestions(currIndex, productSearchTerm);
    // setVariants([]);

  }, [productSearchTerm, currIndex]);


  // Fetch client suggestions as the user types
  // useEffect(() => {
  //   if (!clientSearchTerm) return;

  //   const fetchClients = async () => {
  //     const token = localStorage.getItem("token");
  //     try {
  //       const response = await axios.get(
  //         `https://emkayindia.in/api/v1/client/search?companyID=${selectedCompany.id}&&name=${clientSearchTerm}`,
  //         {
  //           headers: { Authorization: `Bearer ${token}` },
  //         }
  //       );
  //       setClientSuggestions(response.data);
  //     } catch (error) {
  //       console.error("Error fetching clients:", error);
  //       setClientSuggestions([]);
  //     }
  //   };
  //   fetchClients();
  // }, [clientSearchTerm, selectedCompany]);

  useEffect(() => {
    const fetchAllClients = async () => {
      const token = localStorage.getItem("token");
      try {
        const response = await axios.get(
          `https://emkayindia.in/api/v1/client/all?companyID=${selectedCompany.id}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setAllClients(response.data); // Store all clients
        setClientSuggestions(response.data); // Initialize suggestions with all clients
      } catch (error) {
        console.error("Error fetching all clients:", error);
        setAllClients([]);
        setClientSuggestions([]);
      }
    };

    fetchAllClients();
  }, [selectedCompany]);

  // Update client suggestions based on search term
  useEffect(() => {
    if (!clientSearchTerm) {
      setClientSuggestions(allClients); // Show all clients if no search term
    } else {
      const filteredClients = allClients.filter((client) =>
        `${client.name} ${client.nickname} ${client.gstNo}`
          .toLowerCase()
          .includes(clientSearchTerm.toLowerCase())
      );
      setClientSuggestions(filteredClients);
    }
  }, [clientSearchTerm, allClients]);


  // Handle when client is selected from the dropdown
  const handleClientChange = (client) => {
    setSelectedClient(client);
    setClientSearchTerm(
      `${client.name} - ${client.nickname} - ${client.gstNo}`
    );
    setClientSuggestions([]);
  };

  // const fetchProductSuggestions = async (index, searchTerm) => {
  //   if (!searchTerm) return;

  //   const token = localStorage.getItem("token");

  //   try {
  //     const response = await axios.get(
  //       `https://emkayindia.in/api/v1/product/name?companyId=${selectedCompany.id}&&name=${searchTerm}`,
  //       {
  //         headers: { Authorization: `Bearer ${token}` },
  //       }
  //     );
  //     setProductSuggestionList(response.data);
  //     updateBillingProduct(index, { suggestions: response.data }); // Update suggestions for the row
  //   } catch (error) {
  //     console.error("Error fetching product suggestions:", error);
  //   }
  // };
  const fetchProductSuggestions = (index, searchTerm) => {
    if (!searchTerm) {
      updateBillingProduct(index, { suggestions: [] });
      return;
    }

    const filteredSuggestions = allProducts.filter((product) =>
      product.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    updateBillingProduct(index, { suggestions: filteredSuggestions });
  };

  const handleProductSearchChange = (index, searchTerm) => {
    setCurrIndex(index);
  };


  // Update billing product in specific row
  const updateBillingProduct = (
    index,
    updatedProductData,
    productSearchTerm
  ) => {
    const updatedProducts = billingProducts.map((product, i) =>
      i === index ? { ...product, ...updatedProductData } : product
    );
    console.log(updatedProductData);
    setBillingProducts(updatedProducts);
  };

  // Handle product selection
  const handleSelectProduct = async (index, product) => {
    const token = localStorage.getItem("token");
    setSelectedProductName(product);
    try {
      const variantResponse = await axios.get(
        `https://emkayindia.in/api/v1/product/search?companyId=${selectedCompany.id}&&name=${product}&&brand=ABCD&&variant=ABCD`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      // setVariants(variantResponse.data);
      setBillingProducts(index,{productId:variantResponse.data[0].id})
      updateBillingProduct(index, {
        serialNo: index + 1,
        productId: variantResponse.data[0].id,
        name: product,
        suggestions: [],
        brand: variantResponse.data[0].brand,
        gst: 0,
        price: variantResponse.data[0].prices,
        hsn: variantResponse.data[0].hsn,
       
        quantity: 1,
        variant: variantResponse.data[0].variant,
      });
    } catch (error) {
      console.error("Error fetching brands:", error);
    }
  };

  // Handle brand selection and fetch variants
  const selectBrandHandler = async (index, brandName) => {
    // const token = localStorage.getItem("token");
    // setFilteredVariants("");
    // try {
    //   const brandResponse = await axios.get(
    //     `https://emkayindia.in/api/v1/product/search?companyId=${selectedCompany.id}&&name=${selectrfProductName}&&brand=${brandName}&&variant=${selectedVariant}`,
    //     {
    //       headers: { Authorization: `Bearer ${token}` },
    //     }
    //   );
    //   const productDetails = brandResponse.data[0];
    //   console.log(productDetails);
    //   updateBillingProduct(index, {
    //     brand: brandName,
    //     gst: productDetails.gst,
    //     price: productDetails.prices,
    //     hsn: productDetails.hsn,
    //     productId: productDetails.id,
    //     quantity: 1,
    //   });
    //   // setVariants(variantsResponse.data);
    //   // setSelectedBrand(brandName);
    //   // updateBillingProduct(index, { brand: brandName });
    //   setBrands([]);
    //   setVariants([]);
    //   // setSelectedVariant("");
    // } catch (error) {
    //   console.error("Error fetching variants:", error);
    // }
  };

  // Handle variant selection and fetch product details
  const handleVariantSelect = async (index, variant) => {
    // const token = localStorage.getItem("token");
    // console.log(variant);
    // try {
    //   const brandResponse = await axios.get(
    //     `https://emkayindia.in/api/v1/product/brand?companyId=${selectedCompany.id}&&name=${selectrfProductName}&&variant=${variant}`,
    //     {
    //       headers: { Authorization: `Bearer ${token}` },
    //     }
    //   );
    //   setSelectedVariant(variant);
    //   setBrands(brandResponse.data);
    //   console.log(brandResponse.data)
    //   updateBillingProduct(index, { variant: variant });
    //   setFilteredVariants("");
    //   setSearchVariant("");
    //   setVariants("");


    // } catch (error) {
    //   console.error("Error fetching product details:", error);
    // }
  };

  // Calculate the total for each product row
  const calculateRowTotal = (product) => {
    const subtotal = product.quantity * product.price;
    const discountAmount = (subtotal * product.discount) / 100;
    const gstAmount = (subtotal - discountAmount) * (product.gst / 100);
    return subtotal - discountAmount;
  };

  // Calculate the overall total for the invoice
  const calculateCompleteTotal = () => {
    return billingProducts
      .reduce((total, product) => total + product.quantity * product.price, 0)
      .toFixed(2);
  };
  const calculateCompleteDiscount = () => {
    return billingProducts
      .reduce(
        (total, product) =>
          total + (product.quantity * product.price * product.discount) / 100,
        0
      )
      .toFixed(2);
  };
  const calculateCompleteGST = () => {
    return billingProducts
      .reduce(
        (total, product) =>
          total + (product.quantity * product.price * product.gst) / 100,
        0
      )
      .toFixed(2);
  };
  const calculateCompleteGrandTotal = () => {
    return (
      parseFloat(calculateCompleteTotal()) +
      parseFloat(calculateCompleteGST()) +
      parseFloat(packaging) -
      parseFloat(calculateCompleteDiscount())
    ).toFixed(2);
  };

  // Handle adding a new row
  const handleAddRow = () => {
    setBillingProducts([...billingProducts, initialProductRow]);
    console.log(billingProducts);
  };

  // Handle removing a row
  const handleRemoveRow = (Index) => {
    // console.log(billingProducts)
    // setBillingProducts(billingProducts.filter((_, i) => i !== index));
    setBillingProducts((prevBillingProducts) => {
      // Filter out the row that needs to be removed
      const updatedProducts = prevBillingProducts.filter(
        (_, index) => index !== Index
      );

      // Ensure the subsequent rows are reset after the removal to prevent data persisting
      const clearedProducts = updatedProducts.map((product, index) => {
        if (index >= Index) {
          // Clear fields in rows that were shifted up
          return {
            ...product,
            // name: "", // Clear the name
            // variant: "", // Clear the variant
            // brand: "", // Clear the brand
            // searchTerm: "", // Reset the search term
            // suggestions: [], // Clear suggestions
          };
        }
        return product;
      });

      return clearedProducts;
    });
  };

  // Handle form submission and create the quotation
  const handleSubmit = async () => {

    const validProducts = billingProducts.filter(
      (product) => product.name && product.quantity
    );
    if (!selectedCompany || !selectedClient || validProducts.length === 0) {
      alert("Please fill out all fields before submitting.");
      return;
    }

    const token = localStorage.getItem("token");
    const newQuotationData = {
      clientId: selectedClient.id,
      companyId: selectedCompany.id,
      items: validProducts.map((product) => ({
        productId: product.productId,
        serialNo: product.serialNo,
        name: product.name,
        // hsn: product.hsn,
        // brand: product.brand,
        // variant: product.variant,
        hsn: product.hsn,
        brand: "ABCD",
        variant: "ABCD",
        gst: "0",
        total: String(calculateRowTotal(product)),
        quantity: parseInt(product.quantity),
        discount: parseFloat(product.discount) || 0,
        price: parseFloat(product.price),

      })),
      packing: packaging,
      terms: terms,
      banner: selectedCompany.banner,
    };

    console.log(newQuotationData);
    setIsLoading(true);

    try {
      const response = await axios.post(
        "https://emkayindia.in/api/v1/perfoma/create",
        newQuotationData,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (response.status === 200) {
        toast.success("PI created successfully!");
        setShowPrintPreview(true);
        setPrintData(response.data.quotation);
        console.log("res ", response.data.quotation);
        console.log("res print", printData);
      }
    } catch (error) {
      toast.error("Failed to create PI.");
      console.error("Error submitting PI:", error);
    } finally {
      setIsLoading(false);
    }
  };
  const handleTermsChange = (index, value) => {
    // Create a new array to avoid direct state mutation
    const newTerms = [...terms];
    newTerms[index] = value; // Update the specific term at the given index
    setTerms(newTerms); // Update the state with the new array
    console.log(terms);
  };
  const handlePrint = () => {
    //    const printContents = document.getElementById('print-preview').innerHTML;
    // const originalContents = document.body.innerHTML;

    // document.body.innerHTML = printContents; // Temporarily replace body with the print content

    window.print();
    // document.body.innerHTML = originalContents;
  };
  useEffect(() => {
    if (printData) {
      // Proceed only after printData is updated
      setShowPrintPreview(true);
      console.log("Print preview data:", printData);
      // Add further logic that depends on printData being set here
    }
  }, [printData]);
  const gstNumbersMatch = () => {

    const clientGSTPrefix = printData.company.gstNo?.slice(0, 2);
    const companyGSTPrefix = printData.client.gstNo?.slice(0, 2);
    console.log("client", clientGSTPrefix, companyGSTPrefix);
    return clientGSTPrefix === companyGSTPrefix;
  };
  if (showPrintPreview) {
    if (gstNumbersMatch()) {
      return (
        <div className="print-preview w-[100%] h-[99%] overflow-y-auto">
          <div>
            <A4sgst quotationData={printData} className="print " />
          </div>

          <div className="print-actions mx-auto w-[50%]">
            {/* <button onClick={handlePrint} className="print-button">Print Quotation</button> */}
            <div className="flex justify-between w-[100%] mb-8">
              <button onClick={handlePrint} className="print-button">
                Print Quotation
              </button>
              <button
                onClick={() => setShowPrintPreview(false)}
                className="back-button"
              >
                Back to Edit
              </button>
            </div>
          </div>
          <style jsx>{`
          .print-preview {
          color:black;
            background: white;
            // padding: 20px;
            border-radius: 8px;
            width: 101.85%;
            height: 103%;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          }
          .print-actions {
            // margin-top: 20px;
            display: flex;
            justify-content: space-between;
          }
          .print-button,
          .back-button {
            padding: 10px 20px;
            border: none;
            border-radius: 4px;
            cursor: pointer;
            font-size: 16px;
          }
          .print-button {
            background-color: #4caf50;
            color: white;
          }
          .back-button {
            background-color: #f44336;
            color: white;
          }
          @media print {
            .print-actions {
              display: none;
            }
          }
        `}</style>
        </div>
      );
    }
    else {
      return (
        <div className="print-preview w-[100%] h-[99%] overflow-y-auto">
          <div>
            <A4Quotation quotationData={printData} type={'PI'} className="print " />
          </div>

          <div className="print-actions mx-auto w-[50%]">
            {/* <button onClick={handlePrint} className="print-button">Print Quotation</button> */}
            <div className="flex justify-between w-[100%] mb-8">
              <button onClick={handlePrint} className="print-button">
                Print Quotation
              </button>
              <button
                onClick={() => setShowPrintPreview(false)}
                className="back-button"
              >
                Back to Edit
              </button>
            </div>
          </div>
          <style jsx>{`
          .print-preview {
          color:black;
            background: white;
            // padding: 20px;
            // border-radius: 8px;
            width: 101.85%;
            height: 103%;
            // box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          }
          .print-actions {
            // margin-top: 20px;
            display: flex;
            justify-content: space-between;
          }
          .print-button,
          .back-button {
            padding: 10px 20px;
            border: none;
            border-radius: 4px;
            cursor: pointer;
            font-size: 16px;
          }
          .print-button {
            background-color: #4caf50;
            color: white;
          }
          .back-button {
            background-color: #f44336;
            color: white;
          }
          @media print {
            .print-preview {
              overflow: visible !important;
              height: auto !important;
            }
            .print-actions {
              display: none;
            }
            .page-break {
              page-break-before: always;
            }
          }
        `}</style>
        </div>
      );
    }
  }
  let debounceTimer;
  const handleDebouncedSearch = (index, searchTerm) => {
    clearTimeout(debounceTimer); // Clear the previous timer
    debounceTimer = setTimeout(() => {
      // Call the API search handler
      handleProductSearchChange(index, searchTerm);
    }, 500); // Delay of 500ms (adjust based on preference)
  };





  return (
    <div>
      {selectedCompany && (
        <div className="CreateQuotation-component font-sans bg-[#f9f9f9] text-[#848484]  ">
          {isLoading && (
            <div className="absolute  inset-0 flex items-center justify-center  backdrop-blur-sm z-10 ">
              <div className="w-12 h-12 border-4 border-blue-500 border-t-transparent rounded-full animate-spin"></div>
            </div>
            // <div></div>
          )}
          <div className="flex justify-between items-center w-[90%]  mt-2 font-sans  border-b-2 ">
            <h2 className="text-3xl font-bold mt-4 font-sans">
              Create Performa Invoice
            </h2>
            <div
              onClick={() => toggleComponent("AddClient")}
              style={{ backgroundColor: "#C6C5B9" }} // Custom background color
              className="h-8 py-1 px-2 rounded   transition duration-300 ease-in-out hover:white h-1] "
            >
              Add Client
            </div>
          </div>

          {/* Bill Type */}
          {/* <div className="bill-type-container">
            <div className='label'>Select Quotation Type:</div>
            <select value={billType} onChange={(e) => setBillType(e.target.value)}>
              <option value="PI">Proforma Invoice</option>
              <option value="Quotation">Quotation</option>
            </select>
          </div> */}

          {/* Client Selection */}
          <div className="client-section mt-4   font-sans">
            <div className="flex  justify-between w-[80%] ">
              <div className={"w-[100%] "}>
                {/* <div className="w-[50%]">
                  <input
                    type="text"
                    value={clientSearchTerm}
                    onChange={(e) => {
                      setClientSearchTerm(e.target.value);
                      // Optionally, filter clientSuggestions based on search term
                    }}
                    placeholder="Search Client Name, GST No, Nick Name"
                    className="border border-gray-300 p-1 mb-4 w-[90%]"
                    onKeyDown={handleKeyDownClientSearch} // Add keyboard event handling
                  />
                  {clientSearchTerm && clientSuggestions.length > 0 && (
                    <ul
                      className="suggestions-list border  border-gray-300 rounded-md h-auto overflow-y-auto"
                      ref={suggestionsRef}
                    >
                      {clientSuggestions.map((client, index) => (
                        <li
                          key={client.id}
                          onClick={() => {
                            setSelectedClient(client);
                            setClientSearchTerm(""); // Clear search term after selection
                          }}
                          className={`p-2 cursor-pointer ${highlightedIndex === index ? "bg-gray-200" : ""
                            }`}
                          onMouseEnter={() => setHighlightedIndex(index)} // Highlight on mouse hover
                        >
                          {client.name} - {client.nickname} - {client.gstNo}
                        </li>
                      ))}
                    </ul>
                  )}
                </div> */}
                <div className="w-[50%]">
                  <input
                    type="text"
                    value={clientSearchTerm}
                    onChange={(e) => setClientSearchTerm(e.target.value)}
                    placeholder="Search Client Name, GST No, Nick Name"
                    className="border border-gray-300 p-1 mb-4 w-[90%]"
                    onKeyDown={handleKeyDownClientSearch}
                  />
                  {clientSearchTerm && clientSuggestions.length > 0 && (
                    <ul
                      className="suggestions-list border border-gray-300 rounded-md h-auto overflow-y-auto"
                      ref={suggestionsRef}
                    >
                      {clientSuggestions.map((client, index) => (
                        <li
                          key={client.id}
                          onClick={() => {
                            setSelectedClient(client);
                            setClientSearchTerm(""); // Clear search term after selection
                          }}
                          className={`p-2 cursor-pointer ${highlightedIndex === index ? "bg-gray-200" : ""
                            }`}
                          onMouseEnter={() => setHighlightedIndex(index)} // Highlight on mouse hover
                        >
                          {client.name} - {client.nickname} - {client.gstNo}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
                <div className="flex">
                  <div className="flex flex-col w-[95%] border-r-2 pr-8">
                    <input
                      className="border border-gray-300 p-1 mb-2 w-[90%] bg-gray-100"
                      placeholder="Client Name"
                      value={
                        selectedClient
                          ? selectedClient.name
                          : ""
                      }
                      readOnly
                    />
                    <input
                      className="border border-gray-300 p-1 mb-2 w-[90%]"
                      placeholder="Address line 1"
                      value={
                        selectedClient && selectedClient.address
                          ? selectedClient.address.split("/0")[0]
                          : ""
                      }
                      readOnly
                    />
                    <input
                      className="border border-gray-300 p-1 mb-2 w-[90%]"
                      placeholder="Address line 2"
                      value={
                        selectedClient && selectedClient.address
                          ? selectedClient.address.split("/0")[1]
                          : ""
                      }
                      readOnly
                    />
                    <div className="flex justify-between w-[100%]">
                      <input
                        className="border border-gray-300  mb-2 w-[80%] mr-8"
                        placeholder="City"
                        value={
                          selectedClient ? "City : " + selectedClient.city : ""
                        }
                        readOnly
                      />
                      <input
                        className="border border-gray-300  mb-2 w-[80%]"
                        placeholder="Pincode"
                        value={
                          selectedClient
                            ? "Pincode : " + selectedClient.pincode
                            : ""
                        }
                        readOnly
                      />
                    </div>
                    <div className="flex justify-between w-[100%]">
                      <input
                        className="border border-gray-300  mb-2 w-[80%] mr-8"
                        placeholder="State"
                        value={
                          selectedClient
                            ? "State : " + selectedClient.state
                            : ""
                        }
                        readOnly
                      />
                      <input
                        className="border border-gray-300  mb-2 w-[80%] bg-gray-100"
                        placeholder="GST"
                        value={
                          selectedClient
                            ? "GST No. : " + selectedClient.gstNo
                            : ""
                        }
                        readOnly
                      />
                    </div>
                    <div className="flex justify-between w-[100%]">
                      <input
                        className="border border-gray-300  mb-2 w-[80%] mr-8"
                        placeholder="Email"
                        value={
                          selectedClient
                            ? "Email : " + selectedClient.email
                            : ""
                        }
                        readOnly
                      />
                      <input
                        className="border border-gray-300  mb-2 w-[80%] bg-gray-100"
                        placeholder="Phone No."
                        value={
                          selectedClient
                            ? "Phone : " + selectedClient.phone
                            : ""
                        }
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="pl-4 w-[50%] ml-4 space-y-2">
                    <input
                      className="border border-gray-300 p-1 mb-2 w-[90%] bg-gray-100"
                      placeholder="Nick Name"
                      value={
                        selectedClient
                          ? "Nick Name : " + selectedClient.nickname
                          : ""
                      }
                      readOnly
                    />
                    <div>
                      <p className="font-sans font-semibold text-[#848484]">Contact Person</p>
                      <input
                        className="border border-gray-300 p-1 mb-2 w-[90%]"
                        placeholder="Contact Person"
                        value={
                          selectedClient
                            ? selectedClient.contactPerson
                            : ""
                        }
                        readOnly
                      />
                    </div>
                    <div>
                      <p className="font-sans font-semibold"> Email:</p>
                      <input
                        className="border border-gray-300 p-1 mb-2 w-[90%]"
                        placeholder="Contact Person Email"
                        value={
                          selectedClient
                            ?
                            selectedClient.contactEmail
                            : ""
                        }
                        readOnly
                      />
                    </div>
                    <div>
                      <p className="font-sans font-semibold">Phone:</p>
                      <input
                        className="border border-gray-300 p-1 mb-2 w-[90%]"
                        placeholder="Contact Person Phone"
                        value={
                          selectedClient
                            ?
                            selectedClient.contactPhone
                            : ""
                        }
                        readOnly
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Product Table */}
          <table className="product-table ">
            <thead>
              <tr>
                <th className="w-[5%]">S. No.</th>
                <th>Product Name</th>
                <th className="w-[10%]">Variant</th>
                <th className="w-[10%]">Brand</th>
                <th className=" w-28 ">HSN</th>
                <th className="w-[6.5%] px-2">Quantity</th>
                <th className="w-[6.5%] px-2">Rate/Unit</th>
                <th className="w-[4%] px-2">Disc%</th>
                <th className="w-[5%] px-2">GST%</th>
                <th className="w-[8%] px-2">Amount</th>
                <th className="w-[1%] border-none"></th>
                {/* <th>Actions</th> */}
              </tr>
            </thead>
            <tbody>
              {billingProducts.map((product, index) => (
                <tr key={index}>
                  {/* <td><input type="text" value={index+1} onChange={(e) => updateBillingProduct(index, { serialNo: e.target.value })} /></td> */}
                  <td>
                    <p>{index + 1}</p>
                  </td>
                  {/* <td>
                    <input
                      type="text"
                      value={product.name ? product.name : product.searchTerm}
                      placeholder="Search Product"
                      className="placeholder-gray-300"
                      onChange={(e) => {
                        if (
                          index > 0 &&
                          (!billingProducts[index - 1].name ||
                            !billingProducts[index - 1].variant ||
                            !billingProducts[index - 1].brand)
                        ) {
                          // Display warning and prevent input
                          alert(
                            "Please fill out the previous row before proceeding."
                          );
                          return;
                        }
                        setProductSearchTerm(e.target.value);
                        e.preventDefault();
                        updateBillingProduct(index, { name: e.target.value }); // Update the product name in the state
                        // fetchProductSuggestions(index, e.target.value); // Fetch suggestions
                        handleProductSearchChange(index, product.searchTerm);
                      }}
                      onKeyDown={handleKeyDown}
                    />
                    {product.suggestions && product.suggestions.length > 0 && (
                      <ul
                        className="suggestions-list"
                        ref={suggestionsRef} // Reference for scrolling to highlighted item
                      >
                        {product.suggestions.map((suggestion, i) => (
                          <li
                            key={suggestion.id}
                            onClick={() =>
                              handleSelectProduct(index, suggestion.name)
                            }
                            className={`p-2 cursor-pointer ${highlightedIndex === i ? "bg-gray-200" : ""
                              }`} // Highlighting the selected suggestion
                            onMouseEnter={() => setHighlightedIndex(i)}
                            // onKeyDown={handleKeyDown}
                          >
                            {suggestion.name}
                          </li>
                        ))}
                      </ul>
                    )}
                  </td> */}
                  {/* <td>
                    <input
                      type="text"
                      value={product.name ? product.name : product.searchTerm}
                      placeholder="Search Product"
                      className="placeholder-gray-300"
                      onChange={(e) => {
                        if (
                          index > 0 &&
                          (!billingProducts[index - 1].name ||
                            !billingProducts[index - 1].variant ||
                            !billingProducts[index - 1].brand)
                        ) {
                          // Display warning and prevent input
                          alert("Please fill out the previous row before proceeding.");
                          return;
                        }
                        setProductSearchTerm(e.target.value);
                        e.preventDefault();
                        updateBillingProduct(index, { name: e.target.value }); // Update the product name in the state
                        // fetchProductSuggestions(index, e.target.value); // Fetch suggestions
                        handleProductSearchChange(index, e.target.value);
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "ArrowDown" && highlightedIndex < product.suggestions.length - 1) {
                          // Move highlight down
                          setHighlightedIndex((prevIndex) => prevIndex + 1);
                          e.preventDefault();
                        } else if (e.key === "ArrowUp" && highlightedIndex > 0) {
                          // Move highlight up
                          setHighlightedIndex((prevIndex) => prevIndex - 1);
                          e.preventDefault();
                        } else if (e.key === "Enter" && product.suggestions[highlightedIndex]) {
                          // Select the highlighted suggestion
                          handleSelectProduct(index, product.suggestions[highlightedIndex].name);
                          e.preventDefault(); // Prevent default "Enter" behavior
                        }
                      }}
                    />
                    {product.suggestions && product.suggestions.length > 0 && (
                      <ul
                        className="suggestions-list"
                        ref={suggestionsRef} // Reference for scrolling to highlighted item
                      >
                        {product.suggestions.map((suggestion, i) => (
                          <li
                            key={suggestion.id}
                            onClick={() => handleSelectProduct(index, suggestion.name)}
                            className={`p-2 cursor-pointer ${highlightedIndex === i ? "bg-gray-200" : ""
                              }`} // Highlighting the selected suggestion
                            onMouseEnter={() => setHighlightedIndex(i)}
                            style={{
                              backgroundColor: highlightedIndex === i ? "#f0f0f0" : "transparent",
                            }}
                          >
                            {suggestion.name}
                          </li>
                        ))}
                      </ul>
                    )}
                  </td> */}



                  {/* <td className="relative">
                    <input
                      type="text"
                      value={product.name ? product.name : product.searchTerm}
                      placeholder="Search Product"
                      className="placeholder-gray-300 w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none "
                      // readOnly={!!product.name}
                      onChange={(e) => {
                        if (
                          index > 0 &&
                          (!billingProducts[index - 1].name ||
                            !billingProducts[index - 1].variant ||
                            !billingProducts[index - 1].brand)
                        ) {
                          alert("Please fill out the previous row before proceeding.");
                          return;
                        }
                        setProductSearchTerm(e.target.value);
                        e.preventDefault();
                        updateBillingProduct(index, { name: e.target.value });
                        handleProductSearchChange(index, e.target.value);
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "ArrowDown" && highlightedIndex < product.suggestions.length - 1) {
                          setHighlightedIndex((prevIndex) => prevIndex + 1);
                          e.preventDefault();
                        } else if (e.key === "ArrowUp" && highlightedIndex > 0) {
                          setHighlightedIndex((prevIndex) => prevIndex - 1);
                          e.preventDefault();
                        } else if (e.key === "Enter" && product.suggestions[highlightedIndex]) {
                          handleSelectProduct(index, product.suggestions[highlightedIndex].name);
                          const nextInput = document.querySelector(`#variant-input-${index}`);
                          if (nextInput) {
                            nextInput.focus();
                          }
                          e.preventDefault();
                        }
                      }}
                      data-index={index}                      
                    />
                    {product.suggestions && product.suggestions.length > 0 && (
                      <ul
                        className="absolute z-10 bg-white border h-[30vh] overflow-auto border-gray-300 w-full mt-1 rounded-md shadow-lg transition-transform duration-200 ease-in-out"
                        style={{
                          transform: "scaleY(1)",
                          transformOrigin: "top",
                        }}
                      >
                        {product.suggestions.map((suggestion, i) => (
                          <li
                            key={suggestion.id}
                            onClick={() => handleSelectProduct(index, suggestion.name)}
                            className={`px-3 py-2 cursor-pointer ${highlightedIndex === i ? "bg-gray-100" : ""
                              }`}
                            onMouseEnter={() => setHighlightedIndex(i)}
                            style={{
                              transition: "background-color 0.2s ease-in-out",
                            }}
                          >
                            {suggestion.name}
                          </li>
                        ))}
                      </ul>
                    )}
                  </td> */}

                  <td className="relative" id={`product-input-${index}`}>
                    <input
                      type="text"
                      value={product.name ? product.name : product.searchTerm}
                      placeholder="Search Product"
                      className="placeholder-gray-300 w-full px-3 py-2 border  border-gray-300 rounded-md focus:outline-none"
                      // readOnly={selectedVariant ? true : false}
                      readOnly={(product.hsn || product.variant) ? true : false}
                      onChange={(e) => {
                        console.log(billingProducts);
                        if (
                          index > 0 &&
                          (!billingProducts[index - 1].name
                            //  ||
                            // !billingProducts[index - 1].variant ||
                            // !billingProducts[index - 1].brand)
                        )) {
                          alert("Please fill out the previous row before proceeding.");
                          return;
                        }
                        setProductSearchTerm(e.target.value);
                        e.preventDefault();
                        updateBillingProduct(index, {
                          name: e.target.value,
                          brand: '',
                          variant: ''
                        });
                        setFilteredVariants([]);
                        setSearchVariant("");
                        handleProductSearchChange(index, e.target.value);
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "ArrowDown" && highlightedIndex < product.suggestions.length - 1) {
                          setHighlightedIndex((prevIndex) => {
                            const nextIndex = prevIndex + 1;
                            const item = document.querySelector(`#suggestion-item-${nextIndex}`);
                            const dropdown = document.querySelector('.dropdown-list');
                            if (item && dropdown) {
                              const itemRect = item.getBoundingClientRect();
                              const dropdownRect = dropdown.getBoundingClientRect();

                              // Check if item is at the bottom of the dropdown
                              if (itemRect.bottom > dropdownRect.bottom) {
                                dropdown.scrollTop += itemRect.height; // Scroll by one item
                              }
                            }
                            return nextIndex;
                          });
                          e.preventDefault();
                        } else if (e.key === "ArrowUp" && highlightedIndex > 0) {
                          setHighlightedIndex((prevIndex) => {
                            const nextIndex = prevIndex - 1;
                            const item = document.querySelector(`#suggestion-item-${nextIndex}`);
                            const dropdown = document.querySelector('.dropdown-list');
                            if (item && dropdown) {
                              const itemRect = item.getBoundingClientRect();
                              const dropdownRect = dropdown.getBoundingClientRect();

                              // Check if item is at the top of the dropdown
                              if (itemRect.top < dropdownRect.top) {
                                dropdown.scrollTop -= itemRect.height; // Scroll by one item
                              }
                            }
                            return nextIndex;
                          });
                          e.preventDefault();
                        } else if (e.key === "Enter" && product.suggestions[highlightedIndex]) {
                          console.log(product.variant);
                          handleSelectProduct(index, product.suggestions[highlightedIndex].name);
                          updateBillingProduct(index, {
                            name: product.suggestions[highlightedIndex].name,
                            searchTerm: "",
                          });
                          const nextInput = document.querySelector(`#quantity-input-${index}`);
                          if (nextInput) {
                            nextInput.focus();
                          }
                          e.preventDefault();
                        }
                      }}
                      data-index={index}

                    />
                    {product.suggestions && product.suggestions.length > 0 && (
                      <ul
                        className="absolute z-10 bg-white border h-[30vh] overflow-auto border-gray-300 w-full mt-1 rounded-md shadow-lg transition-transform duration-200 ease-in-out dropdown-list"
                        style={{
                          transform: "scaleY(1)",
                          transformOrigin: "top",
                        }}
                      >
                        {product.suggestions.map((suggestion, i) => (
                          <li
                            id={`suggestion-item-${i}`}
                            key={suggestion.id}
                            onClick={() => handleSelectProduct(index, suggestion.name)}
                            className={`px-3 py-2 cursor-pointer ${highlightedIndex === i ? "bg-gray-100" : ""}`}
                            onMouseEnter={() => setHighlightedIndex(i)}
                            style={{
                              transition: "background-color 0.2s ease-in-out",
                            }}
                          >
                            {suggestion.name}
                          </li>
                        ))}
                      </ul>
                    )}
                  </td>



                  {/* <td>
                    {product.name ? (
                      product.variant ? (
                        // Display the variant as non-editable text once selected
                        <input type="text" value={product.variant} readOnly
                          className="text-center w-full"
                        />
                      ) : (
                        // Display the variant dropdown if both product and brand are selected
                        <select
                          value={product.variant}
                          onChange={(e) =>
                            handleVariantSelect(index, e.target.value)
                          }
                        >
                          <option value="">Select Variant</option>
                          {variants.map((variant, i) => (
                            <option key={i} value={variant}>
                              {variant}
                            </option>
                          ))}
                        </select>
                      )
                    ) : (
                      // If product or brand is not selected, show this message
                      <input
                        type="text"
                        placeholder="Select Variant"
                        className="placeholder-gray-300"
                        readOnly
                      />
                    )}
                  </td> */}
                  {/* <td>
                    {product.name ? (
                      product.variant ? (
                        // Display the variant as non-editable text once selected
                        <input
                          type="text"
                          value={product.variant}
                          readOnly
                          className="text-center w-full"
                        />
                      ) : (
                        // Display the searchable variant dropdown
                        <div className="relative">
                          <input
                            type="text"
                            placeholder="Type to search variant"
                            value={product.searchVariant || ""}
                            onChange={(e) => handleVariantFilter(index, e.target.value)}
                            className="w-full px-3 py-2 border border-gray-300 rounded-md"
                          />
                          {filteredVariants.length > 0 && (
                            <ul className="absolute z-10 bg-white border border-gray-300 mt-1 w-full max-h-40 overflow-y-auto">
                              {filteredVariants.map((variant, i) => (
                                <li
                                  key={i}
                                  onClick={() => handleVariantSelect(index, variant)}
                                  className="px-3 py-2 hover:bg-gray-200 cursor-pointer"
                                >
                                  {variant}
                                </li>
                              ))}
                            </ul>
                          )}
                        </div>
                      )
                    ) : (
                      // If product or brand is not selected, show this message
                      <input
                        type="text"
                        placeholder="Select Variant"
                        className="placeholder-gray-300"
                        readOnly
                      />
                    )}
                  </td> */}
                  {/* <td>
                    {product.name ? (
                      product.variant ? (
                        <input
                          type="text"
                          value={product.variant}
                          readOnly
                          className="text-center w-full"
                        />
                      ) : (
                        
                        <div className="relative">
                          <input
                            type="text"
                            placeholder="Type to search variant"
                            value={searchVariant || ""}
                            onChange={(e) => handleVariantFilter(index, e.target.value)}
                            className="w-full px-3 py-2 border border-gray-300 rounded-md"
                            onKeyDown={handleKeyDown}
                          />
                          <ul className="absolute z-10 bg-white border border-gray-300 mt-1 w-full max-h-40 overflow-y-auto">
                            {(filteredVariants.length > 0
                              ? filteredVariants
                              : variants
                            ).map((variant, i) => (
                              <li
                                key={i}
                                onClick={() => handleVariantSelect(index, variant)}
                                className="px-3 py-2 hover:bg-gray-200 cursor-pointer"

                              >
                                {variant}
                              </li>
                            ))}
                          </ul>
                          {product.searchVariant && (
                            <p className="mt-1 text-sm text-gray-500">
                              You typed: <span className="font-semibold">{product.searchVariant}</span>
                            </p>
                          )}
                        </div>
                      )
                    ) : (
                      <input
                        type="text"
                        placeholder="Select Variant"
                        className="placeholder-gray-300"
                        readOnly
                      />
                    )}
                  </td> */}
                  {/* <td>
                    {product.name ? (
                      product.variant ? (
                        
                        <input
                          type="text"
                          value={product.variant}
                          readOnly
                          className="text-center w-full border border-gray-300 rounded-md bg-gray-100"
                        />
                      ) : (
                      
                        <div className="relative">
                          
                          <input
                            type="text"
                            placeholder="Type to search variant"
                            value={searchVariant || ""}
                            onChange={(e) => handleVariantFilter(index, e.target.value)}
                            onKeyDown={(e) => {
                              if (e.key === "ArrowDown" && highlightedIndex < filteredVariants.length - 1) {
                                setHighlightedIndex((prevIndex) => prevIndex + 1);
                                e.preventDefault();
                              } else if (e.key === "ArrowUp" && highlightedIndex > 0) {
                                setHighlightedIndex((prevIndex) => prevIndex - 1);
                                e.preventDefault();
                              } else if (e.key === "Enter" && filteredVariants[highlightedIndex]) {
                                handleVariantSelect(index, filteredVariants[highlightedIndex]);
                                e.preventDefault();
                              }
                            }}
                            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none "
                          />
                         
                          <ul
                            className="absolute z-10 bg-white border border-gray-300 mt-1 w-full max-h-40 overflow-y-auto rounded-md shadow-lg transition-opacity duration-200 ease-in-out"
                            style={{
                              opacity: filteredVariants.length > 0 ? 1 : 0,
                              pointerEvents: filteredVariants.length > 0 ? "all" : "none",
                            }}
                          >
                            {(filteredVariants.length > 0 ? filteredVariants : variants).map((variant, i) => (
                              <li
                                key={i}
                                onClick={() => handleVariantSelect(index, variant)}
                                className={`px-3 py-2 hover:bg-blue-100 cursor-pointer ${highlightedIndex === i ? "bg-gray-100" : ""
                                  }`}
                                onMouseEnter={() => setHighlightedIndex(i)}
                                style={{
                                  transition: "background-color 0.2s ease-in-out",
                                }}
                              >
                                {variant}
                              </li>
                            ))}
                          </ul>
                      
                          {product.searchVariant && (
                            <p className="mt-1 text-sm text-gray-500">
                              You typed: <span className="font-semibold">{product.searchVariant}</span>
                            </p>
                          )}
                        </div>
                      )
                    ) : (
                      
                      <input
                        type="text"
                        placeholder="Select Variant"
                        className="placeholder-gray-300 text-center w-full bg-gray-100"
                        readOnly
                      />
                    )}
                  </td> */}
                  <td >
                    {product.name ? (
                      product.variant ? (
                        // Display the variant as non-editable text once selected
                        <input
                          type="text"
                          value={product.name ? product.variant : ""}
                          // onChange={product.variant=""}
                          className="text-center w-full border border-gray-300 rounded-md bg-gray-100"
                          tabIndex={-1}
                          readOnly
                        />
                      ) : (
                        // Searchable dropdown for variants
                        <div className="relative">
                          {/* Input field for typing initials */}
                          <input
                            type="text"
                            placeholder="Type to search variant"
                            value={searchVariant || ""}
                            onChange={(e) => handleVariantFilter(index, e.target.value)}
                            tabIndex={-1}
                            readOnly
                            onKeyDown={(e) => {
                              const dropdown = document.getElementById(`variant-dropdown-${index}`);

                              if (e.key === "ArrowDown" && highlightedIndex < filteredVariants.length - 1) {
                                setHighlightedIndex((prevIndex) => {
                                  const nextIndex = prevIndex + 1;

                                  // Scroll if the next item is not visible
                                  if (dropdown) {
                                    const nextItem = dropdown.children[nextIndex];
                                    if (nextItem) {
                                      const dropdownBottom = dropdown.scrollTop + dropdown.clientHeight;
                                      const nextItemBottom = nextItem.offsetTop + nextItem.offsetHeight;
                                      if (nextItemBottom > dropdownBottom) {
                                        dropdown.scrollTop += nextItem.offsetHeight;
                                      }
                                    }
                                  }

                                  return nextIndex;
                                });
                                e.preventDefault();
                              } else if (e.key === "ArrowUp" && highlightedIndex > 0) {
                                setHighlightedIndex((prevIndex) => {
                                  const prevIndexValue = prevIndex - 1;

                                  // Scroll if the previous item is not visible
                                  if (dropdown) {
                                    const prevItem = dropdown.children[prevIndexValue];
                                    if (prevItem) {
                                      if (prevItem.offsetTop < dropdown.scrollTop) {
                                        dropdown.scrollTop -= prevItem.offsetHeight;
                                      }
                                    }
                                  }

                                  return prevIndexValue;
                                });
                                e.preventDefault();
                              } else if (e.key === "Enter" && filteredVariants[highlightedIndex]) {
                                handleVariantSelect(index, filteredVariants[highlightedIndex]);

                                // Automatically move focus to the next column
                                const nextInput = document.querySelector(`brand-input-${index}`);
                                if (nextInput) {
                                  nextInput.focus();
                                }
                                e.preventDefault();
                              }
                            }}
                            autoComplete="off"
                            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none"
                            id={`variant-input-${index}`}
                          />
                          {/* Dropdown showing filtered variants */}
                          <ul
                            id={`variant-dropdown-${index}`}
                            className="absolute z-10 bg-white border border-gray-300 mt-1 w-[250%] max-h-40 overflow-y-auto rounded-md shadow-lg transition-opacity duration-200 ease-in-out"
                            style={{
                              opacity: filteredVariants.length > 0 ? 1 : 0,
                              pointerEvents: filteredVariants.length > 0 ? "all" : "none",
                            }}
                          >
                            {(filteredVariants.length > 0 ? filteredVariants : variants).map((variant, i) => (
                              <li
                                key={i}
                                onClick={() => {
                                  handleVariantSelect(index, variant);

                                  // Automatically move focus to the next column
                                  const nextInput = document.getElementById(`brand-input-${index}`);
                                  if (nextInput) {
                                    nextInput.focus();
                                  }
                                }}
                                className={`px-3 py-2 hover:bg-blue-100 cursor-pointer ${highlightedIndex === i ? "bg-gray-100" : ""
                                  }`}
                                onMouseEnter={() => {
                                  setHighlightedIndex(i)
                                  const nextInput = document.getElementById(`brand-input-${index}`);
                                  if (nextInput) {
                                    nextInput.focus();
                                  }
                                }
                                }
                                style={{
                                  transition: "background-color 0.2s ease-in-out",
                                }}
                              >
                                {variant}
                              </li>
                            ))}
                          </ul>
                        </div>
                      )
                    ) : (
                      // If product or brand is not selected, show this message
                      <input
                        type="text"
                        placeholder="Select Variant"
                        className="placeholder-gray-300 text-center w-full bg-gray-100"
                        tabIndex={-1}
                        readOnly
                      />
                    )}
                  </td>



                  <td id={`brand-input-${index}`} tabIndex={-1} onKeyDown={(e) => {
                    if (e.key === "Tab") {
                      // Automatically move focus to the next column
                      const nextInput = document.querySelector(`quantity-input-${index}`);
                      if (nextInput) {
                        nextInput.focus();
                      }
                      e.preventDefault();
                    }
                  }}>
                    {product.name && product.variant ? (
                      product.brand ? (

                        <input type="text" value={product.brand} readOnly
                          className="text-center w-full"
                          id={`brand-input-${index}`}
                        />
                      ) : (

                        <select
                          value={product.brand}
                          onChange={(e) => {
                            selectBrandHandler(index, e.target.value)
                            const nextInput = document.getElementById(`quantity-input-${index}`);
                            if (nextInput) {
                              nextInput.focus();
                            }
                          }
                          }

                        >
                          <option value=""
                            id={`brand-input-${index}`}
                          >Select Brand</option>
                          {brands.map((brand, i) => (
                            <option key={i} value={brand}>
                              {brand}
                            </option>
                          ))}
                        </select>
                      )
                    ) : (

                      <input
                        type="text"
                        placeholder="Select Brand"
                        className="placeholder-gray-300 text-center w-full"
                        id={`brand-input-${index}`}
                        tabIndex={-1}
                        readOnly

                      />
                    )}
                  </td>

                  <td>
                    <input
                      type="text"
                      value={product.hsn}
                      // onChange={(e) =>
                      //   updateBillingProduct(index, { hsn: e.target.value })
                      // }
                      tabIndex={-1}
                      className="text-center w-full"
                      readOnly
                    />
                  </td>
                  <td className="text-center">
                    <input
                      type="number"
                      value={product.quantity}
                      onChange={(e) =>
                        updateBillingProduct(index, {
                          quantity: e.target.value,
                        })
                      }
                      className="text-center w-full"
                      id={`quantity-input-${index}`}
                    />
                  </td>

                  {/* <td className="text-center">
                    <input
                      type="number"
                      value={parseFloat(product.price).toFixed(2)}
                      onChange={(e) =>
                        updateBillingProduct(index, { price: e.target.value })
                      }
                      className="text-center w-full"
                    />
                  </td> */}
                  <td className="text-center">
                    <input
                      type="number"
                      value={product.price || ""}
                      onChange={(e) => updateBillingProduct(index, { price: e.target.value })}
                      onBlur={(e) => {
                        const price = parseFloat(e.target.value).toFixed(2);
                        updateBillingProduct(index, { price });
                      }}
                      className="text-center w-full"
                    />
                  </td>

                  <td >
                    <input
                      type="number"
                      value={product.discount}
                      onChange={(e) =>
                        updateBillingProduct(index, {
                          discount: e.target.value,
                        })
                      }

                      className="text-center w-full"
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      value={product.gst}
                      onChange={(e) =>
                        updateBillingProduct(index, { gst: e.target.value })
                      }
                      className="text-center w-full"
                      readOnly
                      tabIndex={-1}
                    />
                  </td>
                  <td>{calculateRowTotal(product).toFixed(2)}</td>
                  {/* <td className="h-fit">
                    {(product.hsn || !product.name) ? <button
                      className='bg-[#535353]'
                      onClick={() => {
                        handleRemoveRow(index)
                      }}>X</button> : <button
                        className={`${(product.hsn || !product.name)}`}
                      >X</button>}
                  </td> */}
                  <td className="h-fit" tabIndex={-1}>
                    {(product.hsn || !product.name) ? (
                      <button
                        className="bg-[#535353]"
                        onClick={() => {
                          handleRemoveRow(index);
                        }}
                        tabIndex={-1}
                      >
                        X
                      </button>
                    ) : (
                      <button
                        className={`${product.hsn || !product.name}`}
                        tabIndex={-1}
                      >
                        X
                      </button>
                    )}
                  </td>

                </tr>
              ))}
            </tbody>
          </table>
          <div className="flex  justify-between mr-[10rem] align-center">
            <button onClick={handleAddRow} className="add-row w-[10%] py-1">
              Add Row
            </button>
          </div>

          {/* Terms & Conditions */}
          <div className="terms-section  flex justify-between">
            <div className="w-[98%] flex justify-between">
              {/* <div className="w-[70%]">
                <label className="font-normal">Terms and Conditions</label>
                {selectedCompany &&
                  terms.map((term, index) => (
                    <input
                      type="text"
                      key={index}
                      value={term}
                      onChange={(e) => handleTermsChange(index, e.target.value)}
                      placeholder="Enter terms and conditions"
                      className="mb-2"
                    />
                  ))}
              </div> */}
              <div className="w-[70%]">
  <label className="font-normal">Terms and Conditions</label>
  {selectedCompany &&
    Array.from({ length: 7 }).map((_, index) => (
      <input
        type="text"
        key={index}
        value={terms[index] || ""} // Display term if it exists, otherwise show an empty string
        onChange={(e) => handleTermsChange(index, e.target.value)}
        // placeholder="Enter terms and conditions"
        className="mb-2 w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none"
      />
    ))}
</div>

              <div className="w-[25%] mt-6">
                <div className="w-full max-w-sm mx-auto">
                  {/* First Table */}
                  <table className="table-auto border-collapse  w-full mb-4">
                    <tbody className="mb-8">
                      <tr className="bg-gray-100">
                        <td className="border border-gray-300 px-4 py-2 font-semibold w-[60%]">
                          Sub Total
                        </td>
                        <td className="border border-gray-300 px-4 py-2 text-right">
                          {calculateCompleteTotal()}
                        </td>
                      </tr>
                      <tr>
                        <td className="border border-gray-300 px-4 py-2 font-semibold">
                          Discount
                        </td>
                        <td className="border border-gray-300 px-4 py-2 text-right">
                          {calculateCompleteDiscount()}
                        </td>
                      </tr>
                      <tr className="bg-gray-100 mt-4">
                        <td className="border border-gray-300 px-4 py-2 font-semibold">
                          GST Total
                        </td>
                        <td className="border border-gray-300 px-4 py-2 text-right">
                          {calculateCompleteGST()}
                        </td>
                      </tr>

                    </tbody>
                    <div className="w-[220%] h-4 bg-[#f9f9f9] border-white"></div>
                    <tbody className="mt-4">

                      <tr className="bg-gray-100">
                        <td className="border border-gray-300 px-4 py-2 font-semibold">
                          Packaging
                        </td>
                        <td className="border border-gray-300  text-right">
                          <input type="number" className=" w-[100%] text-right border-none" onChange={(e) => setPackaging(parseFloat(e.target.value))} />
                        </td>
                      </tr>
                      <tr>
                        <td className="border border-gray-300 px-4 py-2 font-semibold">
                          Grand Total
                        </td>
                        <td className="border border-gray-300 px-4 py-2 text-right">
                          {calculateCompleteGrandTotal() != "NaN" ? calculateCompleteGrandTotal() : ""}
                        </td>
                      </tr>
                      {/* <tr className="bg-gray-100 opacity-0 border-none ">
                        <td className="border border-gray-300 px-4 py-2 font-semibold">
                          Packaging
                        </td>
                        <td className="border border-gray-300 px-4 py-2 text-right"></td>
                      </tr> */}
                    </tbody>
                  </table>



                  {/* Second Table */}
                  {/* <table className="table-auto border-collapse border border-black w-full">
                    <tbody>
                      <tr className="bg-gray-100">
                        <td className="border border-gray-300 px-4 py-2 font-semibold">
                          GST Total
                        </td>
                        <td className="border border-gray-300 px-4 py-2 text-right ">
                          {calculateCompleteGST()}
                        </td>
                      </tr>
                      <tr>
                        <td className="border border-gray-300 px-4 py-2 font-semibold">
                          Grand Total
                        </td>
                        <td className="border border-gray-300 px-4 py-2 text-right">
                          {calculateCompleteGrandTotal(0)}
                        </td>
                      </tr>
                    </tbody>
                  </table> */}
                </div>
              </div>
            </div>
          </div>

          {/* Submit Button */}
          <div className="w-full flex justify-center items-center">
            <button
              onClick={handleSubmit}

              className=" bg-[#C6C5B9] text-[#848484] w-[20%] py-3 px-4 rounded-lg mt-7 mb-8" // Change opacity on hover
            >
              Create PI
            </button>
          </div>
        </div>
      )}
      {!selectedCompany && (
        <div className="flex justify-center align-middle h-[100%]">
          <p className="my-24 text-red-500 font-semibold mx-36">
            Select the company first
          </p>
        </div>
      )}
    </div>
  );
};

export default CreatePI;
