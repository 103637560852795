import React, { useEffect, useState } from 'react';
import './css/AddClient.css';
import axios from 'axios';
import { toast } from 'react-toastify';

const AddClient = ({ selectedCompany, toggleComponent, isEdit, selectedClient, setIsEdit, setSelectedClient }) => {
  const [clientData, setClientData] = useState({
    name: '',
    nickname: '',
    email: '',
    phone: '',
    address: '',
    gstNo: '',
    contactPerson: '',
    contactEmail: '',
    contactPhone: '',
    companyId: selectedCompany ? selectedCompany.id : "",
    city: '',
    pincode: '',
    state: ''
  });
  const [loading, setLoading] = useState(false);
  const [city, setCity] = useState('');
  const [phone, setPhone] = useState('');
  const [pincode, setPincode] = useState('');
  const [addressLine1, setAddressLine1] = useState('');
  const [addressLine2, setAddressLine2] = useState('');
  const [state, setState] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');


  // Handle input changes
  const handleChange = (e) => {
    setClientData({
      ...clientData,
      [e.target.name]: e.target.value,

    });
  };

  useEffect(() => {
    if (isEdit) {
      setClientData(selectedClient);
      setAddressLine1(selectedClient.address.split("/0")[0]);
      setAddressLine2(selectedClient.address.split("/0")[1])
      setCity(selectedClient.city);
      setPincode(selectedClient.pincode);
      setState(selectedClient.state);
      // setFirstName(selectedClient.contactPerson.trim().split(/\s+/)[0])
      // setLastName(selectedClient.contactPerson.trim().split(/\s+/).length > 1 ? selectedClient.contactPerson.trim().split(/\s+/).slice(1).join(" ") : "")
      const nameParts = selectedClient.contactPerson.trim().split(/\s+/);
      setFirstName(nameParts[0]); // First name is the first part
      setLastName(nameParts.length > 1 ? nameParts.slice(1).join(" ") : ""); // Last name is everything else

    }

  }, [])

  // Handle company selection (multi-select)
  const handleCompanyChange = (e) => {
    const options = Array.from(e.target.selectedOptions, (option) => option.value);
    setClientData({ ...clientData, companyId: options });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    const token = localStorage.getItem('token');
    e.preventDefault();
    setLoading(true);
    console.log(clientData);
    const clientDataToSend = {
      ...clientData,
      address: addressLine1 + '/0' + addressLine2,
      state,
      city,
      pincode,
      contactPerson: firstName + " " + lastName,
    }
    try {
      if (!isEdit) {
        const response = await axios.post(
          'https://emkayindia.in/api/v1/client/create',
          clientDataToSend,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          }
        );
        // setSuccessMessage('Client created successfully!');
        // setError('');
        toast.success("Client Added");

        toggleComponent("CreateQuatation");


        console.log(response.data);
        setLoading(false);
      }
      else {
        const response = await axios.patch(
          `https://emkayindia.in/api/v1/client/update/${selectedClient.id}`,
          clientDataToSend,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          }
        );
        // setSuccessMessage('Client created successfully!');
        // setError('');
        toast.success("Client Updated");
        setIsEdit(null);
        // toggleComponent("AllClients");
        setSelectedClient(null);


        console.log(response.data);
        setLoading(false);
      }
    } catch (err) {
      // setError('Failed to create client. Please try again.');
      // console.error(err);
      toast.error("Error Occured while Adding Client " + err);
      setLoading(false);
    }
  };
  return (
    <div className="add-client-container w-[90%] h-[95vh] text-[#848484] mt-2 overflow-y-auto">
      {/* <div className=' h-[90%] overflow-y-auto '> */}
      <h2 className="text-3xl font-bold text-[#848484] flex justify-between">{isEdit ? "Edit Client" : "Add Client"} {isEdit && <div className='flex justify-end mr-6 w-fit'>

        <button
          className=" bg-[#C6C5B9] text-[#848484] font-semibold text-lg px-4 rounded-sm "
          onClick={() => {
            setSelectedClient(null)
            setIsEdit(null);
          }}
        >
          Back to All Clients
        </button>
      </div>}</h2>
      <form onSubmit={handleSubmit}>
        <div className=" flex flex-col text-[#848484]">
          <div className='mt-4  text-xl border-b-2 mb-4 border-gray-300 flex justify-between'>
            <h3>Client Details</h3>

          </div>
          <div>
            <div className="form-section">
              <label className='text-lg'>Client Name:</label>
              <input type="text" name="name" className='w-[90%]' value={clientData.name} onChange={handleChange} required />

              <div className='flex justify-between w-[90%] '>
                <div className='w-[50%] mr-12'>
                  <label className='text-lg'>Address Line 1:</label>
                  <input type="text" name="address" className='w-[100%] text-md' value={addressLine1} onChange={(e) => setAddressLine1(e.target.value)} required />
                </div>
                <div className='w-[50%] '>
                  <label className='text-lg'>Address Line 2:</label>
                  <input type="text" name="address" className='w-[100%] text-md' value={addressLine2} onChange={(e) => setAddressLine2(e.target.value)} required />
                </div>
              </div>
              <div className="input-sec flex mt-4 justify-between w-[90%]">
                <div className='w-[30%] text-lg'>
                  <label className='text-lg'>City:</label>
                  <input type="text" value={city} onChange={(e) => setCity(e.target.value)} required className="input-field w-[100%]" />
                </div>
                <div className='input-sec w-[30%]'>
                  <label className='text-lg'>Pincode:</label>
                  <input type="text" value={pincode} onChange={(e) => setPincode(e.target.value)} required className="input-field w-[100%]" />
                </div>
                <div className='input-sec w-[30%]'>
                  <label className='text-lg'>State:</label>
                  <input type="text" value={state} className="input-field w-[100%]"
                    onChange={(e) => setState(e.target.value)} />
                </div>
              </div>
              <div className='flex mt-4 justify-between w-[90%]'>
                <div className='w-[50%] mr-12'>
                  <label className='text-lg'>Email:</label>
                  <input type="email" name="email" className='w-[100%]' value={clientData.email} onChange={handleChange} required />
                </div>
                <div className='w-[50%]'>
                  <label className='text-lg'>Phone:</label>
                  <input type="tel" name="phone" className='w-[100%]' value={clientData.phone} onChange={handleChange} required />
                </div>
              </div>
              <div className='flex w-[90%] justify-between'>
                <div className='w-[50%] mr-12'>
                  <label className='text-lg'>GST No:</label>
                  <input type="text" name="gstNo" className='w-[100%]' value={clientData.gstNo} onChange={handleChange} required />
                </div>
                <div className='w-[50%]'>
                  <label className='text-lg'>Nickname:</label>
                  <input type="text" name="nickname" className='w-[100%]' value={clientData.nickname} onChange={handleChange} required />
                </div>
              </div>
              <div className='mt-4  text-xl border-b-2 mb-4 border-gray-300'>
                <h3>Contact Person</h3>
              </div>
              <div className='flex w-[90%] justify-between'>
                <div className='w-[50%] mr-12'>
                  <label className='text-lg'>First Name:</label>
                  <input type="text" name="contactPerson" className='w-[100%]' value={firstName} onChange={e => setFirstName(e.target.value)} required />
                </div>
                <div className='w-[50%]'>
                  <label className='text-lg'>Last Name:</label>
                  <input type="text" name="contactPerson" className='w-[100%]' value={lastName} onChange={e => setLastName(e.target.value)} required /> {/*todo*/}
                </div>
              </div>
              <div className='flex w-[90%] justify-between'>
                <div className='w-[50%] mr-12'>
                  <label className='text-lg'>E-mail :</label>
                  <input type="email" name="contactEmail" className='w-[100%]' value={clientData.contactEmail} onChange={handleChange} required />
                </div>
                <div className='w-[50%]'>
                  <label className='text-lg'>Phone No.:</label>
                  <input type="tel" name="contactPhone" className='w-[100%]' value={clientData.contactPhone} onChange={handleChange} required />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="selected-company">
          {selectedCompany ? (
            <label >Selected Company: <label className='text-green-500'>{selectedCompany.companyName}</label></label>
          ) : (
            <label className='text-red-500'>Select a company first</label>
          )}
        </div> */}
        <div className='flex justify-center align-middle'>
          {loading ? (
            <div className="loader"></div>  // Circular loader component
          ) : (
            <button className="submit-btn mb-12 bg-[#C6C5B9] text-[#848484] font-semibold" type="submit" disabled={loading}>
              {isEdit ? "Edit Client" : "Create Client"}
            </button>
          )}
        </div>

      </form>
      {/* </div> */}
    </div>
  );
};

export default AddClient;
