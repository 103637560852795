// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import Select from 'react-select';
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import { FaDownload } from 'react-icons/fa';

// const BulkProductUpload = ({ toggleComponent }) => {
//     const [file, setFile] = useState(null);
//     const [selectedCompanies, setSelectedCompanies] = useState([]);
//     const [companyOptions, setCompanyOptions] = useState([]);
//     const [isLoading, setIsLoading] = useState(false);

//     useEffect(() => {
//         setIsLoading(true);
//         const fetchCompanies = async () => {
//             try {
//                 const token = localStorage.getItem('token');
//                 const response = await axios.get('https://emkayindia.in/api/v1/company/all', {
//                     headers: { 'Authorization': `Bearer ${token}` }
//                 });

//                 const options = response.data.map(company => ({
//                     value: company.id,
//                     label: company.companyName
//                 }));
//                 setCompanyOptions(options);
//             } catch (error) {
//                 console.error('Error fetching companies:', error);
//                 toast.error('Error loading company list');
//             }finally{
//                 setIsLoading(false);
//             }
//         };

//         fetchCompanies();
//     }, []);

//     const handleFileChange = (e) => {
//         setFile(e.target.files[0]);
//     };

//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         if (!file) {
//             toast.error('Please select a file');
//             return;
//         }
//         if (selectedCompanies.length === 0) {
//             toast.error('Please select at least one company');
//             return;
//         }

//         const formData = new FormData();
//         formData.append('file', file);
//         formData.append('companyIds', JSON.stringify(selectedCompanies.map(company => company.value)));

//         try {
//             setIsLoading(true);
//             const token = localStorage.getItem('token');
//             await axios.post('https://emkayindia.in/api/v1/product/createmany', formData, {
//                 headers: {
//                     'Authorization': `Bearer ${token}`,
//                     'Content-Type': 'multipart/form-data',
//                 },
//             });
//             toast.success('Products uploaded successfully!');
//             // toggleComponent("DashBoard");
//         } catch (error) {
//             toast.error(`Error uploading products: ${error.response?.data?.message || 'Unknown error'}`);
//         }finally{
//             setIsLoading(false);
//         }
//     };

//     const handleDownloadProducts = async () => {
//         try {
//             setIsLoading(true);
//             const token = localStorage.getItem('token');
//             const response = await axios.get('https://emkayindia.in/api/v1/product/download', {
//                 headers: { 'Authorization': `Bearer ${token}` },
//                 responseType: 'blob', // Important for file download
//             });

//             const url = window.URL.createObjectURL(new Blob([response.data]));
//             const link = document.createElement('a');
//             link.href = url;
//             link.setAttribute('download', 'products_data.xlsx'); // Set the filename
//             document.body.appendChild(link);
//             link.click();
//             link.parentNode.removeChild(link);

//             toast.success('Product data downloaded successfully!');
//         } catch (error) {
//             console.error('Error downloading product data:', error);
//             toast.error('Error downloading product data');
//         }
//         finally{
//             setIsLoading(false);
//         }
//     };

// //     return (
// //         <form onSubmit={handleSubmit} className="p-6 bg-white rounded shadow-md w-[40%] mx-auto mt-20">
// //             <h2 className="text-xl font-semibold mb-4">Bulk Product Upload</h2>

// //             <div className="mb-4">
// //                 <label className="block text-gray-700 mb-2">Select File:</label>
// //                 <input
// //                     type="file"
// //                     onChange={handleFileChange}
// //                     className="block w-full text-sm text-gray-600 bg-gray-200 rounded border border-gray-300 cursor-pointer focus:outline-none"
// //                 />
// //             </div>

// //             <div className="mb-4">
// //                 <label className="block text-gray-700 mb-2">Select Companies:</label>
// //                 <Select
// //                     options={companyOptions}
// //                     isMulti
// //                     value={selectedCompanies}
// //                     onChange={setSelectedCompanies}
// //                     className="basic-multi-select"
// //                     classNamePrefix="select"
// //                     placeholder="Select companies..."
// //                 />
// //             </div>

// //             <div className="flex justify-between">
// //                     <button
// //                         type="submit"
// //                         className="bg-[#C6C5B9] text-[#848484] py-2 px-4 mr-2"
// //                     >
// //                         Upload Products
// //                     </button>

// //                     <button
// //                         type="button"
// //                         onClick={handleDownloadProducts}
// //                         className="flex items-center bg-white text-[#848484] py-2 px-4 border-2  hover:border-3 transition duration-300"
// //                     >
// //                         <FaDownload className="mr-2" /> All Products
// //                     </button>
// //                 </div>

// //             <ToastContainer />
// //         </form>
// //     );
// // };

// // export default BulkProductUpload;

// return (
//     <div className="relative h-[100%]">
//         {isLoading && (
//             <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-10 backdrop-blur-sm z-10">
//                 <div className="w-8 h-8 border-4 border-blue-500 border-t-transparent rounded-full animate-spin"></div>
//             </div>
//         )}

//         <form
//             onSubmit={handleSubmit}
//             className={`p-6 bg-white rounded shadow-md w-[40%] mx-auto mt-8 ${
//                 isLoading ? 'pointer-events-none' : ''
//             }`}
//         >
//             <h2 className="text-2xl font-semibold text-[#848484] mb-4">Bulk Product Upload</h2>

//             <div className="mb-4">
//                 <label className="block text-[#848484] mb-2">Select File:</label>
//                 <input
//                     type="file"
//                     onChange={handleFileChange}
//                     className="block w-full text-sm text-gray-600 bg-gray-200 rounded border border-gray-300 cursor-pointer focus:outline-none"
//                 />
//             </div>

//             <div className="mb-4">
//                 <label className="block text-[#848484] mb-2">Select Companies:</label>
//                 <Select
//                     options={companyOptions}
//                     isMulti
//                     value={selectedCompanies}
//                     onChange={setSelectedCompanies}
//                     className="basic-multi-select"
//                     classNamePrefix="select"
//                     placeholder="Select companies..."
//                 />
//             </div>
//             <div className="flex justify-between">
//                 <button
//                     type="submit"
//                     className="bg-[#C6C5B9] text-[#848484] py-2 px-4 mr-2"
//                 >
//                     Upload Products
//                 </button>

//                 <button
//                     type="button"
//                     onClick={handleDownloadProducts}
//                     className="flex items-center bg-white text-[#848484] py-2 px-4 border-2  hover:border-3 transition duration-300"
//                 >
//                     <FaDownload className="mr-2" />Download All Products
//                 </button>
//             </div>

//             <ToastContainer />
//         </form>
//     </div>
// );
// };

// export default BulkProductUpload;

import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import Select from 'react-select';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaDownload } from 'react-icons/fa';

const BulkProductUpload = ({ toggleComponent }) => {
    const [file, setFile] = useState(null);
    const [selectedCompanies, setSelectedCompanies] = useState([]);
    const [companyOptions, setCompanyOptions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [uploadSuccess, setUploadSuccess] = useState(false); // Track upload success
    const fileInputRef = useRef(null); // Ref for file input

    useEffect(() => {
        setIsLoading(true);
        const fetchCompanies = async () => {
            try {
                const token = localStorage.getItem('token');
                const response = await axios.get('https://emkayindia.in/api/v1/company/all', {
                    headers: { 'Authorization': `Bearer ${token}` }
                });

                const options = response.data.map(company => ({
                    value: company.id,
                    label: company.companyName
                }));
                setCompanyOptions(options);
            } catch (error) {
                console.error('Error fetching companies:', error);
                toast.error('Error loading company list');
            } finally {
                setIsLoading(false);
            }
        };

        fetchCompanies();
    }, []);

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!file) {
            toast.error('Please select a file');
            return;
        }
        if (selectedCompanies.length === 0) {
            toast.error('Please select at least one company');
            return;
        }

        const formData = new FormData();
        formData.append('file', file);
        formData.append('companyIds', JSON.stringify(selectedCompanies.map(company => company.value)));

        try {
            setIsLoading(true);
            const token = localStorage.getItem('token');
            await axios.post('https://emkayindia.in/api/v1/product/createmany', formData, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data',
                },
            });
            setUploadSuccess(true); // Set upload success
            toast.success('Products uploaded successfully!');
        } catch (error) {
            toast.error(`Error uploading products: ${error.response?.data?.message || 'Unknown error'}`);
        } finally {
            setIsLoading(false);
        }
    };

    const handleDownloadProducts = async () => {
        try {
            setIsLoading(true);
            const token = localStorage.getItem('token');
            const response = await axios.get('https://emkayindia.in/api/v1/product/download', {
                headers: { 'Authorization': `Bearer ${token}` },
                responseType: 'blob', // Important for file download
            });

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'products_data.xlsx'); // Set the filename
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

            toast.success('Product data downloaded successfully!');
        } catch (error) {
            console.error('Error downloading product data:', error);
            toast.error('Error downloading product data');
        } finally {
            setIsLoading(false);
        }
    };

    const handleUploadAnother = (e) => {
        e.preventDefault();
        setFile(null); // Reset file state
        setSelectedCompanies([]); // Reset selected companies
        setUploadSuccess(false); // Reset upload success state
        if (fileInputRef.current) {
            fileInputRef.current.value = ''; // Reset the file input field
        }
    };

    return (
        <div className="relative h-[100%]">
            {isLoading && (
                <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-10 backdrop-blur-sm z-10">
                    <div className="w-8 h-8 border-4 border-blue-500 border-t-transparent rounded-full animate-spin"></div>
                </div>
            )}

            <form
                onSubmit={handleSubmit}
                className={`p-6 bg-white rounded shadow-md w-[40%] mx-auto mt-8 ${
                    isLoading ? 'pointer-events-none' : ''
                }`}
            >
                <h2 className="text-2xl font-semibold text-[#848484] mb-4">Bulk Product Upload</h2>

                <div className="mb-4">
                    <label className="block text-[#848484] mb-2">Select File:</label>
                    <input
                        type="file"
                        onChange={handleFileChange}
                        ref={fileInputRef} // Attach the ref to the input
                        className="block w-full text-sm text-gray-600 bg-gray-200 rounded border border-gray-300 cursor-pointer focus:outline-none"
                    />
                </div>

                <div className="mb-4">
                    <label className="block text-[#848484] mb-2">Select Companies:</label>
                    <Select
                        options={companyOptions}
                        isMulti
                        value={selectedCompanies}
                        onChange={setSelectedCompanies}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        placeholder="Select companies..."
                    />
                </div>
                <div className="flex justify-between">
                    {!uploadSuccess ? (
                        <button
                            type="submit"
                            className="bg-[#C6C5B9] text-[#848484] py-2 px-4 mr-2"
                        >
                            Upload Products
                        </button>
                    ) : (
                        <button
                            type="button"
                            onClick={handleUploadAnother}
                            className="bg-[#C6C5B9] text-[#848484] py-2 px-4 mr-2"
                        >
                            Upload Another File
                        </button>
                    )}

                    <button
                        type="button"
                        onClick={handleDownloadProducts}
                        className="flex items-center bg-white text-[#848484] py-2 px-4 border-2 hover:border-3 transition duration-300"
                    >
                        <FaDownload className="mr-2" />Download All Products
                    </button>
                </div>

                <ToastContainer />
            </form>
        </div>
    );
};

export default BulkProductUpload;
